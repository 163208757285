<template>
  <ion-page>
    <app-secondary-header title="All Programs" />
    <ion-content>
      <ion-list class="ion-padding" v-if="$apollo.queries.channels.loading">
        <skeleton w="100%" h="40px" />
        <skeleton-channel-list-item v-for="i in 6" :key="i" />
      </ion-list>
      <ion-list class="ion-padding" v-else>
        <template v-if="channels">
          <cs-search-bar v-model="search" placeholder="Search programs" />
          <app-channel-list-item
            v-for="channel in filteredChannels"
            :key="channel.id"
            :channel="channel"
          />
        </template>
        <cs-empty-state v-if="showEmptyState" description="No programs found" />
        <cs-empty-state
          v-if="errorMessage"
          title="Error loading programs"
          :description="errorMessage"
        >
          <cs-button size="small" @click="refresh">Retry</cs-button>
        </cs-empty-state>
        <app-store-button block />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';
import AppChannelListItem from '@/components/channels/ChannelListItem.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';
import SkeletonChannelListItem from '@/components/skeletons/SkeletonChannelListItem.vue';

// GQL
import ListChannels from '@/graphql/channels/ListChannels.gql';

export default {
  components: {
    AppSecondaryHeader,
    AppChannelListItem,
    AppStoreButton,
    SkeletonChannelListItem,
  },
  apollo: {
    channels: {
      query: ListChannels,
      error: (error, vm) => {
        vm.handleError(error);
        return false;
      },
    },
  },
  computed: {
    showEmptyState() {
      return this.channels && this.filteredChannels.length === 0;
    },
    filteredChannels() {
      const trimmed = this.search.trim();
      if (!trimmed) return this.channels;

      const searchTerms = trimmed.split(' ').map((term) => term.toLowerCase());
      if (searchTerms.length < 1) return this.channels;

      const filteredChannels = this.channels.filter((channel) => {
        if (matchSearchProp(channel.title, searchTerms)) return true;
        if (matchSearchProp(channel.speaker, searchTerms)) return true;
        return false;
      });
      return filteredChannels;
    },
  },
  data() {
    return {
      search: '',
      errorMessage: null,
    };
  },
  methods: {
    refresh() {
      this.errorMessage = null;
      this.$apollo.queries.channels.refetch();
    },
    handleError(error) {
      this.errorMessage = error.message;
    },
  },
};

const matchSearchProp = (prop, searchTerms) => {
  const formattedProp = (prop || '').toLowerCase();
  const matches = searchTerms.filter((term) => {
    return formattedProp.indexOf(term) > -1;
  });
  return matches.length === searchTerms.length;
};
</script>

<style scoped></style>
