<template>
  <div class="csn-home-popular-channels">
    <div class="skeleton__wrapper" v-if="$apollo.queries.channels.loading">
      <div class="skeleton__wrapper--header">
        <div>
          <skeleton w="100px" h="15px" />
        </div>
        <div>
          <skeleton w="50px" h="15px" />
        </div>
      </div>
      <div class="skeleton__wrapper--content">
        <div v-for="i in 4" :key="i">
          <skeleton w="120px" h="120px" />
          <skeleton w="50px" h="12px" m="5px" />
        </div>
      </div>
    </div>
    <template v-else>
      <cs-category-heading
        class="csn-home-popular-channels__header"
        title="Popular Programs"
        button="See all"
        @action="viewAllPopular"
      />
      <div class="csn-home-popular-channels__content">
        <template v-if="channels">
          <div class="csn-home-popular-channels__horizontal-list">
            <app-channel-suggestion-portrait-item
              v-for="channel in channels"
              :key="channel.id"
              :channel="channel"
              class="csn-home-popular-channels__list-item"
            />
          </div>
        </template>
        <cs-empty-state
          v-if="showEmptyState"
          description="No popular programs found."
        >
        </cs-empty-state>
        <cs-empty-state
          v-if="errorMessage"
          title="Error loading popular programs"
          :description="errorMessage"
        >
          <cs-button size="small" corners="rounded" @click="refresh"
            >Retry</cs-button
          >
        </cs-empty-state>
      </div>
    </template>
  </div>
</template>

<script>
import AppChannelSuggestionPortraitItem from '@/components/channels/ChannelSuggestionPortraitItem.vue';

// GQL
import ListPopularChannels from '@/graphql/channels/ListPopularChannels.gql';

export default {
  components: {
    AppChannelSuggestionPortraitItem,
  },
  apollo: {
    channels: {
      query: ListPopularChannels,
      error: (error, vm) => {
        vm.handleError(error);
        return false;
      },
    },
  },
  computed: {
    showEmptyState() {
      return !this.channels;
    },
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  methods: {
    handleError(error) {
      this.errorMessage = error.message;
    },
    refresh() {
      this.errorMessage = null;
      this.$apollo.queries.channels.refetch();
    },
    viewAllPopular() {
      this.$router.push({ name: 'channels-all' });
    },
  },
};
</script>

<style scoped>
.csn-home-popular-channels__header {
  padding: 0 16px;
}
.csn-home-popular-channels__horizontal-list {
  white-space: nowrap;
  overflow-x: scroll;
}

.skeleton__wrapper {
  margin-left: 15px;
  margin-right: 15px;
}
.skeleton__wrapper--header {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 12px;
}
.skeleton__wrapper--content {
  display: flex;
  gap: 12px;
}
</style>
