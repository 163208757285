import $toast from '@/services/toast';

// Allows for await timeout
const promiseTimeout = (duration) => {
  duration = duration || 0;
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

const formatDateInterval = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return startDate.toLocaleDateString() === endDate.toLocaleDateString()
    ? `${startDate.toLocaleString('default', { weekday: 'long' })}, 
        ${startDate.toLocaleDateString('en-uk', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })} `
    : `${startDate.toLocaleDateString('en-uk', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })} 
        - ${endDate.toLocaleDateString('en-uk', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })} `;
};

const formatTimeInterval = (start, end) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  return `${startTime.toLocaleTimeString('default', {
    hour: 'numeric',
    minute: 'numeric',
  })} 
          - ${endTime.toLocaleTimeString('default', {
            hour: 'numeric',
            minute: 'numeric',
          })} `;
};

const time_ago = (time) => {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds == 0) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == 'string') return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
};

const copyToClipboard = (text) => {
  const input = document.createElement('input');
  input.value = text;

  /* Select the text field */
  input.select();
  input.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(input.value);
  $toast.show({ message: 'Copied!' });
};

export {
  promiseTimeout,
  formatDateInterval,
  formatTimeInterval,
  copyToClipboard,
  time_ago,
};
