<template>
	<ion-header class="csn-secondary-header">
		<cs-secondary-header
			class="csn-secondary-header__header"
			:title="title"
			align-title="center"
			@back="goBack"
		/>
	</ion-header>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style scoped>
.csn-secondary-header .csn-secondary-header__header {
	box-shadow: none;
	--cs-secondary-header-background-color: var(--csn-gray-primary);
	--cs-gray-03: var(--cs-gray-08);
}
</style>
