<template>
  <div class="csn-push-notifications-toggle" v-if="supportsPushNotifications">
    <div class="csn-push-notifications-toggle__item">
      <span
        class="csn-push-notifications-toggle__label cs-textstyle-detail-heading"
        @click.stop="showPushInfo"
      >
        Push Notifications
        <i class="cs-icons-info csn-push-notifications-toggle__icon" />
      </span>
      <span @click.stop="checkEnabled">
        <cs-toggle
          :disabled="loadingPushRegistration || !hasPushPermissions"
          class="csn-push-notification-toggle__toggle"
          :value="checkedState"
          @input="toggleChange"
        />
      </span>
    </div>
  </div>
</template>
<script>
import $dialog from '@/services/dialog';

import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('notifications', {
      supportsPushNotifications: 'supportsPushNotifications',
      loadingPushRegistration: 'loadingPushRegistration',
      pushRegistered: 'pushRegistered',
      hasPushPermissions: 'hasPushPermissions',
    }),
  },
  data() {
    return {
      checkedState: false,
    };
  },
  watch: {
    pushRegistered: {
      immediate: true,
      handler() {
        console.log('hadnler');
        this.checkedState = this.pushRegistered;
      },
    },
  },
  methods: {
    checkEnabled() {
      console.log('checkEnabled', this.hasPushPermissions);
      if (!this.hasPushPermissions) this.enableNotifications();
    },
    toggleChange(toggleVal) {
      console.log('tc', this.checkedState, toggleVal);
      toggleVal ? this.enableNotifications() : this.disableNotifications();
    },
    enableNotifications() {
      console.log('ena');
      this.$store.dispatch('notifications/enableNotifications');
    },
    disableNotifications() {
      console.log('disa');
      this.$store.dispatch('notifications/disableNotifications');
    },
    showPushInfo() {
      const enabledText = this.pushEnabled ? 'enabled' : 'disabled';
      $dialog.show({
        title: 'Push Notifications',
        message: `This allows you to receive notification alerts on your device.  \n\nPush notifications are \t${enabledText}`,
      });
    },
  },
};
</script>
<style scoped>
.csn-push-notifications-toggle__item {
  background-color: var(--csn-gray-secondary);
  margin-bottom: 16px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.csn-push-notifications-toggle__label {
  color: var(--cs-gray-06);
  display: flex;
  align-items: center;
  gap: 4px;
}
.csn-push-notifications-toggle__icon {
  padding: 10px;
  margin: -10px;
  font-size: 20px;
  line-height: 20px;
}
.csn-push-notification-toggle__toggle {
  display: inline-block;
  width: 48px;
  /* --cs-gray-00: var(--cs-gray-00); */
  /* --cs-gray-01: var(--cs-gray-01); */
  --cs-gray-02: var(--cs-gray-03);
}
.dark .csn-push-notification-toggle__toggle {
  /* --cs-gray-00: var(--csn-gray-primary);
  --cs-gray-01: var(--cs-gray-primary);
  --cs-gray-02: var(--csn-gray-secondary); */
  --cs-gray-00: var(--cs-gray-00);
  --cs-gray-01: var(--cs-gray-06);
  --cs-gray-02: var(--cs-gray-03);
}
</style>
