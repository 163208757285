<template>
	<ion-page>
		<app-secondary-header title="Support" />
		<ion-content>
			<ion-list class="ion-padding"> No support page at this time </ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';

export default {
	components: {
		AppSecondaryHeader,
	},
	data() {
		return {
			info: '',
		};
	},
};
</script>

<style scoped></style>
