<template>
  <div class="csn-social-buttons">
    <cs-social-icon-button
      class="csn-social-button"
      network="facebook"
      @click="openFacebook"
    />
    <cs-social-icon-button
      class="csn-social-button"
      network="instagram"
      @click="openInstagram"
      icon="cs-icons-instagram-filled"
    />
    <cs-social-icon-button
      class="csn-social-button"
      network="youtube"
      @click="openYoutube"
    />
  </div>
</template>
<script>
import $iab from '@/services/inappbrowser';

const facebook_url = 'https://facebook.com/csnradio';
const instagram_url = 'https://instagram.com/csnradio';
const youtube_url = 'https://www.youtube.com/c/CSNRADIO';

export default {
  methods: {
    openFacebook() {
      $iab.openExternal(facebook_url);
    },
    openInstagram() {
      $iab.openExternal(instagram_url);
    },
    openYoutube() {
      $iab.openExternal(youtube_url);
    },
  },
};
</script>
<style scoped>
.csn-social-buttons {
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 40px;
}
.csn-social-buttons .csn-social-button {
  background-color: var(--cs-primary-base);
}
</style>
