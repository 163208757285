<template>
  <ion-page>
    <ion-content>
      <div class="csn-confirm-location__content">
        <div class="csn-confirm-location__content-items">
          <div class="ion-text-center">
            <img class="csn-confirm-location__icon" :src="icon" alt="" />
            <div class="csn-confirm-location__description">
              <div class="cs-textstyle-item-heading">Confirm Your Location</div>
              <div class="cs-textstyle-informative-paragraph">
                This helps us show the right content for your location
              </div>
            </div>
          </div>
          <div class="csn-confirm-location__inputs">
            <select-state v-model="selectedState"></select-state>

            <cs-button
              variant="primary"
              corners="rounded"
              @click="onContinue"
              :disabled="!selectedState"
              >Continue</cs-button
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import SelectState from '@/components/general/SelectState.vue';
import UpdateState from '@/graphql/user/UpdateState.gql';
import { mapGetters } from 'vuex';

const icon = require('@/assets/images/confirm-location-icon.svg');

export default {
  components: { SelectState },
  data() {
    return {
      icon,
      selectedState: null,
    };
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    userOnboarded() {
      return this.$store.getters['auth/userOnboarded'];
    },
  },
  watch: {
    userOnboarded(newVal) {
      if (newVal) {
        this.$router.push('/tabs/home');
      }
    },
  },
  methods: {
    async onContinue() {
      await this.$apollo.mutate({
        mutation: UpdateState,
        variables: {
          id: this.user.id,
          stateId: this.selectedState,
        },
        update: (store, resp) => {
          this.$store.dispatch('auth/updateUser', resp.data.user);
          this.$router.push('/tabs/notabs/channel-suggestions');
        },
      });
    },
  },
};
</script>
<style scoped>
ion-content {
  --padding-bottom: 0;
}
.csn-confirm-location__content {
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.csn-confirm-location__icon {
  margin-bottom: 2rem;
}

.csn-confirm-location__description {
  padding-left: 2rem;
  padding-right: 2rem;
}
.csn-confirm-location__description > div:first-child {
  margin-bottom: 8px;
}
.csn-confirm-location__inputs {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
