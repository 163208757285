import { getApolloClient } from '@/vue-apollo';

import FollowChannel from '@/graphql/channels/FollowChannel.gql';
import UnfollowChannel from '@/graphql/channels/UnfollowChannel.gql';
import ListUserChannels from '@/graphql/channels/ListUserChannels.gql';

import $toast from '@/services/toast';

export default {
  namespaced: true,
  state: {
    followIds: [],
  },
  getters: {
    isFollowingChannel: (state) => (channelId) => {
      return state.followIds.indexOf(channelId) > -1;
    },
  },
  mutations: {
    setFollowIds(state, follows) {
      const followsSet = new Set(follows);
      state.followIds = Array.from(followsSet);
    },
    addFollowId(state, channelId) {
      const follows = state.followIds;
      follows.push(channelId);
      const followsSet = new Set(follows);
      state.followIds = Array.from(followsSet);
    },
    removeFollowId(state, channelId) {
      const followIds = state.followIds.filter((id) => id !== channelId);
      state.followIds = followIds;
    },
    resetOnLogout(state) {
      state.followIds = [];
    },
  },
  actions: {
    followChannel({ commit, rootGetters }, channelId) {
      commit('addFollowId', channelId);
      getApolloClient()
        .mutate({
          mutation: FollowChannel,
          variables: {
            id: channelId,
            userId: rootGetters['auth/userId'],
          },
        })
        .catch((error) => {
          $toast.show(`Unable to save action: ${error.message}`);
          commit('removeFollowId', channelId);
        });
    },
    unfollowChannel({ commit, rootGetters }, channelId) {
      commit('removeFollowId', channelId);
      getApolloClient()
        .mutate({
          mutation: UnfollowChannel,
          variables: {
            id: channelId,
            userId: rootGetters['auth/userId'],
          },
        })
        .catch((error) => {
          $toast.show(`Unable to save action: ${error.message}`);
          commit('addFollowId', channelId);
        });
    },
    async fetchFollowIds({ dispatch }, userId) {
      // Called by auth module after getting the user id
      const {
        data: { user_channels },
      } = await getApolloClient().query({
        query: ListUserChannels,
        variables: {
          userId,
        },
        fetchPolicy: 'network-only',
        error: () => {
          return false;
        },
      });
      const followIds = user_channels.map((f) => f.channel.id);
      dispatch('setFollowIds', followIds);
    },
    setFollowIds({ commit }, channelIds) {
      commit('setFollowIds', channelIds);
    },
    logout({ commit }) {
      commit('resetOnLogout');
    },
  },
};
