<template>
  <ion-page>
    <app-secondary-header title="Notifications" />
    <ion-content>
      <ion-list
        class="ion-padding"
        v-if="$apollo.queries.notifications.loading"
      >
        <div class="skeleton__header">
          <skeleton w="70px" h="15px" />
        </div>
        <skeleton-notification-list-item v-for="i in 10" :key="i" />
      </ion-list>
      <ion-list class="ion-padding" v-else>
        <app-push-notifications-toggle />
        <template v-if="notifications && notifications.length">
          <app-notifications-list
            v-for="(monthYearNotifications, key) in notificationsByMonthAndYear"
            :key="key"
            :notifications="monthYearNotifications"
          />
        </template>
        <cs-empty-state
          v-else-if="notifications && notifications.length === 0"
          title="No notifications yet"
          description="Follow programs to get notified when they are playing"
        >
          <cs-button slot="button" @click="seePrograms">
            <span>All Programs</span>
          </cs-button>
        </cs-empty-state>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';
import AppNotificationsList from '@/components/notifications/NotificationsList.vue';
import AppPushNotificationsToggle from '@/components/notifications/PushNotificationsToggle.vue';
import SkeletonNotificationListItem from '@/components/skeletons/SkeletonNotificationListItem.vue';

import ListNotifications from '@/graphql/notifications/ListNotifications.gql';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    AppSecondaryHeader,
    AppNotificationsList,
    AppPushNotificationsToggle,
    SkeletonNotificationListItem,
  },
  apollo: {
    notifications: {
      query: ListNotifications,
      variables() {
        return {
          userId: this.userId,
        };
      },
      fetchPolicy: 'network',
    },
  },
  async mounted() {
    this.updatePageView();
  },
  async beforeDestroy() {
    this.updatePageView();
  },
  methods: {
    seePrograms() {
      this.$router.push({ name: 'channels-all' });
    },
    ...mapActions('notifications', {
      updatePageView: 'updateLastViewedNotificationsPage',
    }),
  },
  computed: {
    ...mapGetters('auth', {
      userId: 'userId',
    }),
    notificationsByMonthAndYear() {
      const ordered = {};
      if (this.notifications) {
        for (var i = 0, len = this.notifications.length; i < len; i++) {
          var entry = this.notifications[i];
          var entryMonth = new Date(entry.created_at).getMonth();
          var entryYear = new Date(entry.created_at).getFullYear();
          const yearAndMonth = `${entryMonth}-${entryYear}`;
          if (!ordered[yearAndMonth]) {
            ordered[yearAndMonth] = [];
          }
          ordered[yearAndMonth].push(entry);
        }
      }
      return ordered;
    },
  },
};
</script>

<style scoped>
.skeleton__header {
  display: flex;
  justify-content: center;
}
</style>
