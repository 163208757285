<template>
  <div v-if="loading">
    <skeleton-list-item />
  </div>

  <div class="csn-live-shows-list" v-else>
    <app-show-list-item v-if="liveShow" :show="liveShow" />
    <cs-empty-state
      v-if="showEmptyState"
      description="No live shows right now"
    />
    <cs-empty-state
      v-if="loadingError"
      title="Error loading live show"
      :description="loadingError"
    >
      <cs-button size="small" @click="refresh">Retry</cs-button>
    </cs-empty-state>
  </div>
</template>
<script>
import AppShowListItem from '@/components/shows/ShowListItem.vue';
import SkeletonListItem from '@/components/skeletons/SkeletonListItem.vue';
import { mapState } from 'vuex';

export default {
  components: {
    AppShowListItem,
    SkeletonListItem,
  },
  computed: {
    ...mapState('shows', {
      liveShow: 'liveShow',
      loading: 'isLoading',
      loadingError: 'loadingError',
    }),
    showEmptyState() {
      return !this.loading && !this.liveShow;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$store.dispatch('shows/fetch');
    },
  },
};
</script>
<style scoped></style>
