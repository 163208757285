<template>
  <div class="csn-push-notifications-button" v-if="showPushSettings">
    <cs-button @click="enableNotifications" corners="rounded" block>
      <i class="cs-icons-notification-filled" />
      &nbsp;Enable Push Notifications
    </cs-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('notifications', {
      showPushSettings: 'showPushSettings',
    }),
  },
  methods: {
    enableNotifications() {
      this.$store.dispatch('notifications/enableNotifications');
    },
  },
};
</script>
<style scoped>
.csn-push-notifications-toggle__item {
  --background: var(--csn-gray-secondary);
}
.csn-push-notifications-toggle__icon {
  padding: 10px;
  margin: -10px;
}
</style>
