<template>
  <div class="csn-onboarding">
    <ion-page>
      <ion-content>
        <div class="csn-onboarding__content">
          <ion-slides
            ref="slides"
            pager
            :options="slideOpts"
            @ionSlidesDidLoad="slidesLoaded"
            @ionSlideDidChange="updateIndex"
          >
            <ion-slide>
              <onboarding-slide
                :picture="onboarding1Image"
                title="Live Radio & Programs"
                description="Listen live, find schedules, and follow your favorite programs"
              />
            </ion-slide>
            <ion-slide>
              <onboarding-slide
                :picture="onboarding2Image"
                title="Stay Connected"
                description="Receive a notification when your favorite programs start"
              />
            </ion-slide>
            <ion-slide>
              <onboarding-slide
                :picture="onboarding3Image"
                title="Give Your Support"
                description="Help keep God's Word on the air by becoming a partner today"
              />
            </ion-slide>
          </ion-slides>

          <div class="csn-onboarding__buttons">
            <cs-button
              v-if="index > 0"
              class="csn-onboarding__button csn-onboarding__button-back"
              fill="clear"
              @click="back"
            >
              <i class="cs-icons-back-angle-filled"></i>
              Back
            </cs-button>
            <cs-button
              v-if="index < 3"
              class="csn-onboarding__button csn-onboarding__button-next"
              fill="clear"
              @click="next"
            >
              Next
              <i class="cs-icons-next-angle-filled"></i>
            </cs-button>
          </div>
        </div>
      </ion-content>
    </ion-page>
  </div>
</template>

<script>
import OnboardingSlide from '@/components/general/OnboardingSlide.vue';

const onboarding1Image = require('@/assets/images/Onboarding1.svg');
const onboarding2Image = require('@/assets/images/Onboarding2.svg');
const onboarding3Image = require('@/assets/images/Onboarding3.svg');

export default {
  components: {
    OnboardingSlide,
  },
  data() {
    return {
      slideOpts: {
        initialSlide: 0,
        slideShadows: true,
      },
      index: 0,
      onboarding1Image,
      onboarding2Image,
      onboarding3Image,
    };
  },
  mounted() {
    const onBoarded = this.$store.getters['auth/userOnboarded'];
    if (onBoarded) {
      this.$router.push('/tabs/notabs/login');
    }
  },
  methods: {
    async slidesLoaded() {
      const swiper = await this.$refs.slides.getSwiper();
      swiper.update();
    },
    async back() {
      this.$refs.slides.slidePrev();
    },
    async next() {
      if (this.index === 2) {
        this.$store.dispatch('auth/onboardUser');
        this.$router.push('/tabs/notabs/login');
      } else this.$refs.slides.slideNext();
    },
    async updateIndex() {
      this.index = await this.$refs.slides.getActiveIndex();
    },
  },
};
</script>

<style scoped>
ion-content {
  --padding-bottom: 0;
}
.csn-onboarding__content {
  background: url('~@/assets/images/Onboarding Background.svg');
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
}
.csn-onboarding ion-slides {
  height: calc(100% - env(safe-area-inset-bottom));
  width: 100vw;
}
.csn-onboarding__button.cs-button.cs-button--clear {
  position: absolute;
  z-index: 1;
  text-transform: var(--cs-transform-upper);
  --cs-button-text-color: var(--cs-white);
  --cs-button-text-color-hover: var(--cs-white);
}
.csn-onboarding__buttons .csn-onboarding__button i {
  font-size: 12px;
}
.csn-onboarding__button-back {
  bottom: calc(10px + env(safe-area-inset-bottom));
  left: 0;
}
.csn-onboarding__button-back i {
  margin-right: 10px;
}
.csn-onboarding__button-next {
  bottom: calc(10px + env(safe-area-inset-bottom));
  right: 0;
}
.csn-onboarding__button-next i {
  margin-left: 10px;
}
::v-deep .swiper-pagination-bullet {
  background: #0061fa;
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background: var(--cs-white);
}
</style>
