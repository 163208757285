<template>
	<app-donate-details>
		<div class="cs-textstyle-item-heading csn-donate-details__title">
			Tower Keepers
		</div>
		<div class="cs-textstyle-paragraph csn-donate-details__description">
			<p>
				A Tower Keeper is any CSN supporter who wants their donation to go
				directly toward their local CSN station.
			</p>
		</div>
		<app-donate-button />
	</app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
export default {
	components: {
		AppDonateDetails,
		AppDonateButton,
	},
};
</script>
