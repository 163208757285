<template>
  <ion-page class="csn-channel">
    <app-secondary-header title="Program" />
    <ion-content>
      <skeleton-channel-detail v-if="$apollo.queries.channel.loading" />
      <ion-list v-else>
        <template v-if="channel">
          <div class="csn-channel__profile">
            <img
              v-if="channel.picture"
              class="csn-channel__profile-picture"
              :src="channel.picture"
            />
            <div class="csn-channel__profile-details">
              <div class="csn-channel__title cs-textstyle-page-subtitle">
                {{ channel.title }}
              </div>
              <div class="csn-channel__speaker cs-textstyle-paragraph">
                {{ channel.speaker }}
              </div>
              <div class="csn-channel__buttons">
                <div class="csn-channel__social-buttons">
                  <cs-social-icon-button
                    v-if="channel.url"
                    @click="openUrl"
                    network="network"
                    size="medium"
                  />
                </div>
                <app-channel-follow-button :channel="channel" />
              </div>
            </div>
          </div>
          <app-show-schedule
            class="csn-channel__show-schedule"
            :channel="channel"
          />
        </template>
        <cs-empty-state v-if="showEmptyState" description="No program found" />
        <cs-empty-state
          v-if="errorMessage"
          title="Error loading program"
          :description="errorMessage"
        >
          <cs-button size="small" @click="refresh">Retry</cs-button>
        </cs-empty-state>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';

import AppChannelFollowButton from '@/components/channels/ChannelFollowButton.vue';

import AppShowSchedule from '@/components/shows/ShowSchedule.vue';
import SkeletonChannelDetail from '@/components/skeletons/SkeletonChannelDetail.vue';

// GQL
import GetChannel from '@/graphql/channels/GetChannel.gql';

import $iab from '@/services/inappbrowser';

export default {
  components: {
    AppSecondaryHeader,
    AppChannelFollowButton,
    AppShowSchedule,
    SkeletonChannelDetail,
  },
  apollo: {
    channel: {
      query: GetChannel,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      error: (error, vm) => {
        vm.handleError(error);
        return false;
      },
      manual: true,
      result({ data, loading }) {
        if (!loading && data) {
          this.channel = data.channel;
        }
      },
    },
  },
  computed: {
    showEmptyState() {
      return !this.$apollo.queries.channel.loading && !this.channel;
    },
  },
  data() {
    return {
      errorMessage: null,
      channel: null,
    };
  },
  mounted() {
    this.$store.dispatch('shows/fetch');
  },
  methods: {
    refresh() {
      this.errorMessage = null;
      this.$apollo.queries.channel.refetch();
      this.$store.dispatch('shows/fetch', true);
    },
    handleError(error) {
      this.errorMessage = error.message;
    },
    openUrl() {
      if (this.channel && this.channel.url) $iab.openExternal(this.channel.url);
    },
  },
};
</script>

<style scoped>
ion-content {
  --padding-bottom: 0;
}
.dark .csn-channel__profile {
  background-color: var(--csn-gray-secondary);
}
.csn-channel__profile-picture {
  width: 100%;
}
.csn-channel__profile-details {
  padding: 16px;
}
.csn-channel__title {
  color: var(--cs-gray-08);
}
.csn-channel__speaker {
  color: var(--cs-gray-04);
}
.csn-channel__description {
  color: var(--cs-gray-05);
  padding-top: 16px;
}
.dark .csn-channel__description {
  color: var(--cs-gray-08);
}
.csn-channel__buttons {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
}
.csn-channel__social-buttons {
  display: flex;
  gap: 8px;
}
.csn-channel__show-schedule {
  padding-top: 16px;
}
</style>
