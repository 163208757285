<template>
  <div class="csn-header-ads" v-if="showAds">
    <ion-slides pager @ionSlidesDidLoad="onSlidesLoaded">
      <ion-slide
        v-for="ad in validAds"
        :key="ad.id"
        class="csn-header-ads__slide"
      >
        <div class="csn-header-ads__image-holder">
          <img
            class="csn-header-ads__image"
            :src="ad.image_url"
            @click="openAd(ad.click_url)"
          />
        </div>
      </ion-slide>
    </ion-slides>
  </div>
  <div v-else>
    <skeleton w="100%" h="246px" />
  </div>
</template>
<script>
import $iab from '@/services/inappbrowser';

import ListHeaderAds from '@/graphql/home/ListHeaderAds.gql';

export default {
  apollo: {
    header_ads: {
      query: ListHeaderAds,
    },
  },
  computed: {
    validAds() {
      if (!this.header_ads) return [];
      return this.header_ads.filter((ad) => ad.image_url);
    },
    showAds() {
      return this.header_ads && this.header_ads.length;
    },
  },
  methods: {
    openAd(adUrl) {
      adUrl && $iab.openExternal(adUrl);
    },
    onSlidesLoaded(slides) {
      if (!slides || !slides.target) return;
      slides.target.options = {
        autoplay: { delay: 5000, disableOnInteraction: false },
      };
      slides.target.startAutoplay();
    },
  },
};
</script>
<style>
/* Warning: scoped styling does not work on this component, but don't style anything else here */
.csn-header-ads .swiper-wrapper {
  padding-bottom: 28px;
}
.csn-header-ads .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}
.csn-header-ads .swiper-pagination-bullet {
  background-color: var(--cs-gray-04);
  opacity: 1;
}
.csn-header-ads .swiper-pagination-bullet-active {
  background-color: var(--cs-primary-base);
}
</style>
<style scoped>
.csn-header-ads {
  margin-bottom: 36px;
}
.csn-header-ads__slides {
}
.csn-header-ads__slide {
}

.csn-header-ads__image-holder {
  width: 100%;
}
.csn-header-ads__image {
  width: 100%;
  cursor: pointer;
}
</style>
