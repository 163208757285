<template>
  <cs-button
    class="csn-delete-account"
    fill="clear"
    variant="danger"
    block
    @click="confirmDelete"
    >Delete Account</cs-button
  >
</template>
<script>
import $dialog from '@/services/dialog';

export default {
  components: {},
  methods: {
    async confirmDelete() {
      const confirmValue = 'delete';
      const response = await $dialog.promptDelete({
        title: 'Confirm Account Deletion',
        message: `This will delete your profile, any saved settings, and log you out.  This cannot be undone.  Please type the word "${confirmValue}" to confirm.`,
        okayLabel: 'Delete',
        confirmValue,
      });
      if (response === confirmValue) {
        this.$store.dispatch('auth/deleteAccount');
      }
    },
  },
};
</script>
<style scoped>
.csn-delete-account {
  margin-top: 60px;
}
</style>
