<template>
  <div class="csn-notification-item">
    <div class="csn-notification-item__row">
      <div>
        <cs-avatar
          :picture="notification.channel.picture"
          :name="notification.channel.title"
        />
      </div>
      <div class="csn-notification-item__row-details">
        <div
          class="cs-textstyle-paragraph csn-notification-item__row-description"
          v-if="notification.notification_type === 'live'"
        >
          <div>
            <span
              class="cs-textstyle-paragraph-bold csn-notification-item__row-name"
              >{{ notification.channel.title }}</span
            >
            <span v-if="isLive"> is live</span>
            <span v-if="isNext"> is starting soon</span>
            <span v-if="isPast"> was live</span>
          </div>
          <app-live-tag v-if="isLive" />
        </div>
        <div v-if="notification.notification_type === 'new'">
          <div>
            <span class="cs-textstyle-paragraph gray05-text">New program </span
            ><span
              class="cs-textstyle-paragraph-bold csn-notification-item__row-name"
              >"{{ notification.channel.title }}"</span
            >
          </div>
        </div>
        <div
          class="cs-textstyle-paragraph-small csn-notification-item__row-time"
        >
          {{ timeSinceNotification }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLiveTag from '@/components/general/LiveTag.vue';
import { time_ago } from '@/services/utils';

import { mapState } from 'vuex';

export default {
  components: {
    AppLiveTag,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('shows', {
      liveShow: 'liveShow',
    }),
    isLive() {
      if (!this.liveShow || !this.notification) return false;
      const liveChannel = this.liveShow.channel;
      if (liveChannel.id !== this.notification.channel_id) return false;
      return true;
    },
    isNext() {
      if (this.isLive) return false;
      const createdDate = new Date(this.notification.created_at);
      const tenMinutes = 1000 * 60 * 10;
      if (Date.now() - createdDate < tenMinutes) return true;
      return false;
    },
    isPast() {
      if (!this.isLive && !this.isNext) return true;
      return false;
    },
    timeSinceNotification() {
      if (!this.notification) return;
      return time_ago(this.notification.created_at);
    },
  },
};
</script>

<style scoped>
.csn-notification-item {
  padding: 10px;
  background-color: var(--csn-gray-secondary);
  color: var(--cs-gray-08);
  margin-bottom: 16px;
}
.csn-notification-item__row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.csn-notification-item__row-time {
  color: var(--cs-gray-04);
}
.csn-notification-item__row-description {
  color: var(--cs-gray-05);
  display: flex;
  justify-content: space-between;
}
.csn-notification-item__row-name {
  color: var(--cs-gray-08);
}
.csn-notification-item__row-details {
  width: 100%;
}
.gray05-text {
  color: var(--cs-gray-05);
}
</style>
