<template>
  <ion-page>
    <app-main-header title="Donate" />
    <ion-content class="csn-donate">
      <ion-list class="ion-padding">
        <div class="ion-padding csn-donate__top-block">
          <div class="cs-textstyle-section-heading csn-donate__top-block-title">
            {{ blockTitle }}
          </div>
          <div class="cs-textstyle-paragraph csn-donate__top-block-content">
            {{ blockContent }}
          </div>
        </div>
        <cs-button-group
          size="small"
          :values="selectedOptions"
          :buttons="donateOptions"
          wrap
          @input="selectedOptions = $event"
        />
        <component :is="selectedOption.component" />
        <app-store-button />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppOneTimeDetails from '@/components/donate/OneTimeDetails.vue';
import AppSubscribeDetails from '@/components/donate/SubscribeDetails.vue';
import AppTowerKeeperDetails from '@/components/donate/TowerKeeperDetails.vue';
import AppPropertyDetails from '@/components/donate/PropertyDetails.vue';
import AppPhoneMailDetails from '@/components/donate/PhoneMailDetails.vue';
import AppCancelDetails from '@/components/donate/CancelDetails.vue';

import AppStoreButton from '@/components/store/StoreButton.vue';

const blockTitle = 'Donate to CSN Radio';
const blockContent = `CSN is listener supported. If you would like to partner with CSN, by helping us cover our cost of operation to broadcast God's Word nationally and across the world, and to reach the unsaved with the Gospel, please consider making a donation. All donations are tax-deductible.`;
const contributeTitle = 'Contribute to the community';

const donateOptions = [
  {
    label: 'One Time',
    value: 0,
    component: AppOneTimeDetails,
  },
  {
    label: 'Become a Partner',
    value: 1,
    component: AppSubscribeDetails,
  },
  {
    label: 'Tower Keeper',
    value: 2,
    component: AppTowerKeeperDetails,
  },
  {
    label: 'Property',
    value: 3,
    component: AppPropertyDetails,
  },
  {
    label: 'Phone/Mail',
    value: 4,
    component: AppPhoneMailDetails,
  },
  {
    label: 'Cancel',
    value: 5,
    component: AppCancelDetails,
  },
];

export default {
  components: {
    AppMainHeader,
    AppStoreButton,
  },
  computed: {
    selectedOption() {
      return this.selectedOptions[0];
    },
  },
  data() {
    return {
      blockTitle,
      blockContent,
      contributeTitle,
      donateOptions,
      selectedOptions: [donateOptions[0]],
    };
  },
  methods: {},
};
</script>

<style scoped>
.csn-donate__top-block {
  background-color: var(--csn-gray-secondary);
  margin-bottom: 24px;
}
.csn-donate__top-block-title {
  margin-bottom: 8px;
}
</style>
