<template>
  <cs-button
    class="csn-channel-follow-button"
    size="small"
    corners="rounded"
    :fill="buttonFill"
    @click.stop="toggleFollow"
    >{{ buttonTitle }}</cs-button
  >
</template>
<script>
import { mapGetters } from 'vuex';

import $toast from '@/services/toast';

export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('follows', {
      isFollowingChannel: 'isFollowingChannel',
    }),
    isFollowing() {
      return this.isFollowingChannel(this.channel.id);
    },
    buttonTitle() {
      return this.isFollowing ? 'Unfollow' : 'Follow';
    },
    buttonFill() {
      return this.isFollowing ? 'outline' : 'solid';
    },
  },
  methods: {
    async toggleFollow() {
      const channelId = this.channel.id;
      if (this.isFollowing) {
        this.$store.dispatch('follows/unfollowChannel', channelId);
        this.$emit('followed', [channelId, false]);
        const toastResponse = await $toast.show({
          message: 'Unfollowed',
          button: 'Undo',
          timeout: 3000,
        });
        if (toastResponse === 'Undo') {
          this.$store.dispatch('follows/followChannel', channelId);
          this.$emit('followed', [channelId, true]);
        }
      } else {
        this.$store.dispatch('follows/followChannel', channelId);
        this.$emit('followed', [channelId, true]);
      }
    },
  },
};
</script>
<style scoped>
.csn-channel-follow-button {
  align-self: flex-start;
  --cs-button-padding: 6px 9px;
}
</style>
