<template>
  <div class="csn-privacy-terms__links-holder">
    <app-link
      class="csn-privacy-terms__links"
      href="https://csnradio.app/terms.html"
      >Terms of Service</app-link
    >
    <app-link
      class="csn-privacy-terms__links"
      href="https://csnradio.app/privacy.html"
      >Privacy Policy</app-link
    >
  </div>
</template>
<script>
import AppLink from '@/components/general/Link.vue';
export default {
  components: {
    AppLink,
  },
};
</script>
<style scoped>
.csn-privacy-terms__links-holder {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.csn-privacy-terms__links {
  opacity: 0.8;
  cursor: pointer;
  text-decoration: none;
  color: var(--cs-gray-05);
}
</style>
