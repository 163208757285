<template>
  <ion-page>
    <app-secondary-header title="About Us" />
    <ion-content>
      <ion-list class="ion-padding">
        <img class="csn-about-us__image" :src="coverImage" />
        <div class="cs-textstyle-paragraph csn-about__content">
          {{ pageContent }}
        </div>
        <app-store-button />
        <app-csn-social-buttons />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';
import AppCsnSocialButtons from '@/components/general/CsnSocialButtons.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';

const coverImage = require('@/assets/images/About_Us_Cover.png');
const pageContent = `As Christians, we are called to serve. CSN International was a dream that became a reality wrought from the dedication of one man with a God-given vision to serve fellow Christians and reach those still in darkness. As this vision became a reality, CSN was born, and God’s Word started playing through the airways. Since that time, CSN has grown through the financial support of our listeners and the blessings of God.

Paul Wrote in Romans 12:2, “And be not conformed to this world, but be transformed by the renewing of your mind, that you may prove what is good, and acceptable and perfect will of God.” In this era of mass media, the danger of being drawn in by earthly desires is equally strong. As in Paul’s day, we too are living in the world, Satan’s domain. It is a challenge not to allow ourselves to be influenced by the secular teachings around us. What was once a sanctuary from the world—our homes—have come under attack as the enemy uses technology to infiltrate them with destructive ideas, images, and words.

Anti-Christian values are all around us in the media, online, in entertainment, and plastered on billboards and bumper stickers. Tolerance in the mantra of those who would abolish a Christian’s right to speak freely on Biblical teachings. Unfortunately, tolerance is reserved for their view, and not for God’s. Jesus’s teachings are desperately needed in our society now more than ever. The very foundation of our Christian values have come under attack by those who oppose the moral teachings of the Bible.

Our mission is the feeding of God’s sheep as instructed in His Holy Word. Our aim is to follow these instructions by broadcasting Biblical teachings and anointed Praise and Worship Music 24 hours a day to communities throughout the United States and the world. Jesus said we become strong by the Word of God abiding in us, and our desire is that you will find this ministry to be a point of spiritual growth, maturity, and encouragement.`;

export default {
  components: {
    AppSecondaryHeader,
    AppCsnSocialButtons,
    AppStoreButton,
  },
  data() {
    return {
      pageContent,
      coverImage,
    };
  },
};
</script>

<style scoped>
.csn-about-us__image {
  width: 100%;
  margin-bottom: 24px;
}
.csn-about__content {
  color: var(--cs-gray-08);
  white-space: pre-line;
}
</style>
