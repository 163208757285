import { promiseTimeout } from '@/services/utils';

const defaultOptions = {
  timeout: 3000,
};

const show = (options) => {
  return new Promise((resolve) => {
    if (typeof options === 'string') {
      _popup(
        {
          ...defaultOptions,
          message: options,
        },
        resolve
      );
    } else {
      _popup(
        {
          ...defaultOptions,
          ...options,
        },
        resolve
      );
    }
  });
};

const _popup = async (options, resolve) => {
  const toast = document.createElement('div');
  toast.className = 'dlo-toast';

  const content = document.createElement('div');
  content.className = 'dlo-toast__content';
  content.addEventListener('click', () => {
    resolve(true);
    _hideToast(toast);
  });
  toast.appendChild(content);

  const message = document.createElement('div');
  message.className = 'dlo-toast__message cs-textstyle-paragraph';
  message.innerText = options.message;
  content.appendChild(message);

  if (options.button) {
    const button = document.createElement('div');
    button.className = 'dlo-toast__button';
    button.innerText = options.button;
    button.addEventListener('click', (e) => {
      e.stopPropagation();
      resolve(options.button);
      _hideToast(toast);
    });
    content.appendChild(button);
  }

  const countdown = document.createElement('div');
  countdown.className = 'dlo-toast__countdown';
  countdown.style['transition-duration'] = `${options.timeout}ms`;
  content.appendChild(countdown);

  document.body.appendChild(toast);
  await promiseTimeout(10);

  toast.classList.add('dlo-toast--active');
  countdown.classList.add('dlo-toast__countdown--active');
  await promiseTimeout(options.timeout);

  resolve(true);
  _hideToast(toast);
};

const _hideToast = async (toast) => {
  if (!toast) return;

  toast.classList.remove('dlo-toast--active');
  await promiseTimeout(200);

  toast.remove();
};

export default {
  show,
};
