<template>
  <cs-button
    v-if="canSignInWithApple"
    class="sign-in-with-apple"
    key="apple"
    corners="rounded"
    @click="loginApple"
  >
    <img :src="appleLogo" />
    Sign in with Apple</cs-button
  >
</template>
<script>
const appleLogo = require('@/assets/images/siwa.png');

export default {
  props: {
    upgradeAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appleLogo,
      canSignInWithApple: false,
    };
  },
  methods: {
    loginApple() {
      this.upgradeAccount
        ? this.$store.dispatch('auth/upgradeApple')
        : this.$store.dispatch('auth/loginApple');
    },
  },

  created() {
    if (window.cordova) {
      document.addEventListener('deviceready', () => {
        if (window.cordova.platformId !== 'ios') return;
        if (!window.cordova.plugins.SignInWithApple) return;
        this.canSignInWithApple = true;
      });
    }
  },
};
</script>
<style scoped>
.sign-in-with-apple.cs-button {
  --cs-button-padding: 0 16px;
  --cs-button-color: #fff;
  --cs-button-color-hover: #fff;
  --cs-button-text-color: #000;
  --cs-button-text-color-hover: #000;
  border-color: #ddd;
}
.sign-in-with-apple.cs-button img {
  height: 38px;
}
</style>
