import jwtDecode from 'jwt-decode';
import $router from '@/router';

import {
  getApolloClient,
  getTempApolloClient,
  reloadApollo,
} from '@/vue-apollo';
import LoginAsGuest from '@/graphql/user/LoginAsGuest.gql';
import DeleteAccount from '@/graphql/user/DeleteAccount.gql';

import * as simpleStorage from '@/services/simple-storage';
import $dialog from '@/services/dialog';
import $loading from '@/services/loading';
import $toast from '@/services/toast';

let _inAppBrowserInstance = null;
let _inAppBrowserMonitor = null;

import GetUser from '@/graphql/user/GetUser.gql';

export default {
  namespaced: true,
  state: {
    user: null,
    userOnboarded: null,
    userLoggedIn: null,
    loginWindowOpen: false,
  },

  getters: {
    user: (state) => state.user,
    userId: (state) => (state.user ? state.user.id : null),
    userOnboarded: (state) => state.userOnboarded,
    userLoggedIn: (state) => state.userLoggedIn,
  },

  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    async setUserOnboarded(state, val) {
      state.userOnboarded = val ? 1 : null;
      val
        ? await simpleStorage.setItem('csn_onboarded', 1)
        : await simpleStorage.removeItem('csn_onboarded');
    },
    async setUserLoggedIn(state, val) {
      state.userLoggedIn = val;
      val
        ? await simpleStorage.setItem('csn_token', val)
        : await simpleStorage.removeItem('csn_token');
    },
    setLoginWindowOpen(state, val) {
      state.loginWindowOpen = val;
    },
  },

  actions: {
    async fetchUserLoginState({ commit, state }) {
      if (state.userLoggedIn) return 'loggedin';
      if (state.userOnboarded) return 'onboarded';
      const token = await simpleStorage.getItem('csn_token');
      if (token) {
        commit('setUserLoggedIn', token);
        return 'loggedin';
      }
      const onboarded = await simpleStorage.getItem('csn_onboarded');
      if (onboarded) {
        commit('setUserOnboarded', onboarded);
        return 'onboarded';
      }
      return false;
    },
    async setInitialState({ commit }) {
      const onboarded = await simpleStorage.getItem('csn_onboarded');
      const token = await simpleStorage.getItem('csn_token');
      if (onboarded) commit('setUserOnboarded', onboarded);
      if (token) commit('setUserLoggedIn', token);
    },
    async onboardUser({ commit }) {
      commit('setUserOnboarded', true);
    },

    async loginUser({ commit, dispatch }, token) {
      commit('setUserLoggedIn', token);
      const userId = jwtDecode(token).id;
      const {
        data: { user: me },
      } = await getApolloClient().query({
        query: GetUser,
        variables: {
          id: userId,
        },
      });
      commit('setUser', me);
      dispatch('follows/fetchFollowIds', userId, { root: true });
      dispatch('notifications/init', null, { root: true });
    },

    async updateUser({ commit }, user) {
      commit('setUser', user);
    },

    async logout({ commit, dispatch }) {
      dispatch('audio/stop', null, { root: true });
      dispatch('follows/logout', null, { root: true });
      await dispatch('notifications/logout', null, { root: true });
      await simpleStorage.removeItem('csn_token');
      await simpleStorage.removeItem('theme');
      await simpleStorage.removeItem('notificationsLastChecked');
      await simpleStorage.removeItem('guestUpgrade');
      commit('setUserLoggedIn', false);
      commit('setUser', null);
      reloadApollo();
      $router.push({
        name: 'login',
      });
    },

    // BELOW
    // Migrated in login functions from Login view and Edit Profile Modal
    // This handles logging in and upgrading guest accounts

    // Local login for guest user
    async loginAsGuest({ dispatch }) {
      try {
        $loading.show();
        const url = `https://services-graph.graphexpress.com/get_anonymous_token?application=${process.env.VUE_APP_GX_APP_ID}`;
        const response = await fetch(url);
        const anonymousToken = await response.json();
        const {
          data: { token },
        } = await getTempApolloClient(anonymousToken).mutate({
          mutation: LoginAsGuest,
        });
        $loading.hide();
        dispatch('_handleAccessToken', token);
      } catch (e) {
        $loading.hide();
        $dialog.alert(
          `There was an error logging in as a guest. ${JSON.stringify(e)}`
        );
      }
    },

    // Upgrade guest account to social login provider
    async upgradeGuest({ dispatch }, { provider, guestId }) {
      return dispatch('_login', { provider, guestId });
    },

    _signInApplePromise() {
      return new Promise((resolve, reject) => {
        window.cordova.plugins.SignInWithApple.signin(
          { requestedScopes: [0, 1] },
          (res) => {
            const fullName = res.fullName
              ? `${res.fullName.givenName} ${res.fullName.familyName}`.trim()
              : '';
            resolve({
              email: res.email,
              name: fullName,
              application: process.env.VUE_APP_GX_APP_ID,
              user: res.user,
            });
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async _saveApple(_, options) {
      const url = 'https://services-graph.graphexpress.com/login_with_apple';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      });
      const token = await response.json();
      return token;
    },

    async loginApple({ dispatch }) {
      try {
        const appleDetails = await dispatch('_signInApplePromise');
        const token = await dispatch('_saveApple', appleDetails);
        dispatch('_handleAccessToken', token);
      } catch (e) {
        $dialog.alert('Error upgrading your account.  ' + JSON.stringify(e));
      }
    },
    async upgradeApple({ dispatch, state }) {
      try {
        const appleDetails = await dispatch('_signInApplePromise');
        await simpleStorage.setItem('guestUpgrade', Date.now());
        appleDetails.guest_user_id = state.user ? state.user.id : null;
        const token = await dispatch('_saveApple', appleDetails);
        dispatch('_handleAccessToken', token);
      } catch (e) {
        $dialog.alert('Error upgrading your account.  ' + JSON.stringify(e));
      }
    },

    // Login using social login.  Provider may be 'facebook', 'google', 'twitter'
    async loginProvider({ dispatch }, provider) {
      return dispatch('_login', { provider });
    },

    // Use GX login system and guest upgrade (if applicable)
    async _login({ dispatch }, { provider, guestId }) {
      const gxAppId = process.env.VUE_APP_GX_APP_ID;
      const providerId = provider.toUpperCase();
      if (guestId) await simpleStorage.setItem('guestUpgrade', Date.now());
      const guestCallback = guestId ? `&guest_user_id=${guestId}` : '';
      const callbackUrl = window.cordova
        ? process.env.VUE_APP_NATIVE_CALLBACK_URL + guestCallback
        : `${window.location.origin}/auth.html${guestCallback}`;
      const loginLink = `https://auth.csnradio.app/login?application=${gxAppId}&provider=${providerId}&callback=${callbackUrl}`;
      return window.cordova
        ? dispatch('_appLogin', loginLink)
        : dispatch('_genericLogin', loginLink);
    },

    // App login will perform social login using InAppBrowser window
    //  and execute script in the window to detect access token variable
    async _appLogin({ commit, dispatch }, loginLink) {
      _inAppBrowserInstance = window.cordova.InAppBrowser.open(
        loginLink,
        '_blank'
      );
      setTimeout(() => commit('setLoginWindowOpen', true), 100);
      _inAppBrowserInstance.addEventListener('exit', () =>
        commit('setLoginWindowOpen', false)
      );
      if (_inAppBrowserMonitor) clearInterval(_inAppBrowserMonitor);
      _inAppBrowserMonitor = setInterval(() => {
        if (_inAppBrowserInstance) {
          // csnnoredirect variable so auth.html does not redirect on mobile
          // csnaccesstoken is a local variable created by auth.html if there is a token in URL
          _inAppBrowserInstance.executeScript(
            {
              code: `
                window.csnnoredirect = true;
                window.csnaccesstoken;
              `,
            },
            (data) => {
              const token = data[0];
              if (token === 'ACCESS_DENIED') {
                clearInterval(_inAppBrowserMonitor);
                _inAppBrowserInstance.close();
                $toast.show('Unable to log in');
              } else if (token) {
                clearInterval(_inAppBrowserMonitor);
                _inAppBrowserInstance.close();
                dispatch('_handleAccessToken', token);
              }
            }
          );
        }
      }, 500);
    },

    // Generic web login will open the login page replacing the current page
    // public/auth.html will redirect user to Login.vue when login complete
    async _genericLogin(_, loginLink) {
      window.open(loginLink, '_self');
    },

    // Called from Login.vue with access token
    async completeGenericLogin({ dispatch }, token) {
      return dispatch('_handleAccessToken', token);
    },

    async _handleAccessToken({ dispatch }, token) {
      if (!token) return;
      await simpleStorage.setItem('csn_token', token);
      await reloadApollo();

      dispatch('loginUser', token);

      // If login is a guest user upgrading their account;
      const guestUpgrade = await simpleStorage.getItem('guestUpgrade');
      const fifteenMinutes = 1000 * 60 * 15;
      if (guestUpgrade && Date.now() - 1 * guestUpgrade < fifteenMinutes) {
        // Remember, app upgrade will automatically close the login window
        // On web login for upgrading user account, redirect to menu page
        if (!window.cordova) $router.replace({ name: 'menu' });
        // and don't proceed further for web and app user upgrade
        return;
      }
      $router.push({ name: 'confirm-location' });
    },

    async deleteAccount({ dispatch }) {
      await getApolloClient().mutate({
        mutation: DeleteAccount,
      });
      await $dialog.alert('Account deleted.  Logging you out');
      dispatch('logout');
    },
  },
};
