<template>
  <app-modal v-bind="$attrs" @close="$emit('close')" title="Edit Profile">
    <div v-if="user && user.is_guest">
      <div class="ion-padding csn-login__top-block">
        <div class="cs-textstyle-section-heading csn-login__top-block-title">
          Login
        </div>
        <div class="cs-textstyle-paragraph csn-login__top-block-content">
          Connect one of your social accounts to personalize your profile and
          save the programs you follow
        </div>
      </div>
      <div class="csn-login__social-buttons">
        <cs-login-button
          v-for="provider in loginProviders"
          :key="provider"
          :network="provider"
          corners="rounded"
          @click="login(provider)"
          >Connect {{ provider }}</cs-login-button
        >
        <app-login-apple-button upgrade-account />
      </div>
    </div>
    <div v-else>
      <cs-form v-model="formValid">
        <cs-input
          class="input"
          label="Name"
          v-model="userData.name"
          required
        ></cs-input>
        <cs-file-input
          class="input"
          label="Picture"
          v-model="userData.picture"
          accept-type="image/*"
          :application-id="gxAppId"
        ></cs-file-input>
      </cs-form>
      <div>
        <br />
        <select-state v-model="userData.state_id"></select-state>
      </div>
      <br />
      <cs-button
        @click="save()"
        :disabled="!formValid"
        block
        corners="rounded"
        fill="outline"
        >Save</cs-button
      >
    </div>
    <app-delete-account />
  </app-modal>
</template>
<script>
import AppModal from '@/components/modals/Modal.vue';
import AppLoginAppleButton from '@/components/general/LoginAppleButton.vue';
import SelectState from '@/components/general/SelectState.vue';
import AppDeleteAccount from '@/components/menu/DeleteAccount.vue';

import UpdateUser from '@/graphql/user/UpdateUser.gql';
import { mapState, mapGetters } from 'vuex';

const APPLICATION_ID = process.env.VUE_APP_GX_APP_ID;

export default {
  components: {
    AppModal,
    AppLoginAppleButton,
    SelectState,
    AppDeleteAccount,
  },
  computed: {
    ...mapState('auth', {
      loginWindowOpen: 'loginWindowOpen',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    userData() {
      return { ...this.user };
    },
  },
  data() {
    return {
      formValid: false,
      loginProviders: ['facebook', 'google'],
      gxAppId: APPLICATION_ID,
    };
  },
  methods: {
    login(provider) {
      this.$store.dispatch('auth/upgradeGuest', {
        provider,
        guestId: this.user.id,
      });
    },
    async save() {
      await this.$apollo.mutate({
        mutation: UpdateUser,
        variables: {
          id: this.userData.id,
          name: this.userData.name,
          picture: this.userData.picture,
          stateId: this.userData.state_id ? this.userData.state_id : null,
        },
        update: (store, resp) => {
          this.$store.dispatch('auth/updateUser', resp.data.user);
          this.$emit('close');
        },
      });
    },
  },
};
</script>

<style scoped>
.csn-login__top-block {
  color: var(--cs-gray-08);
  background-color: var(--csn-gray-secondary);
  margin-bottom: 24px;
}
.csn-login__top-block-title {
  margin-bottom: 8px;
}
.csn-login__social-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.csn-login__social-buttons >>> .cs-login-button {
  text-transform: capitalize;
}
</style>
