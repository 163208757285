import { render, staticRenderFns } from "./LiveShowsList.vue?vue&type=template&id=224e5f91&scoped=true&"
import script from "./LiveShowsList.vue?vue&type=script&lang=js&"
export * from "./LiveShowsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "224e5f91",
  null
  
)

export default component.exports