import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
  createApolloClient,
  restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client';
import { createHttpLink } from 'apollo-link-http';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'csn_token';

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';

const headers = {};
// headers['x-hasura-admin-secret'] = process.env.VUE_APP_GRAPHEXPRESS_SECRET;

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
  headers,
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: null, // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: httpLink,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export function getTempApolloClient(token) {
  const tempHttpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    link: tempHttpLink,
  });

  return apolloClient;
}

export function getApolloClient() {
  return _apolloClient;
}

let _apolloClient;

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;
  _apolloClient = apolloClient;
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {},
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function reloadApollo() {
  await createProvider();
  if (_apolloClient.wsClient) restartWebsockets(_apolloClient.wsClient);
  try {
    await _apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}
