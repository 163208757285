<template>
  <div class="csn-channel-suggestion-portrait-item" @click="viewChannel">
    <img
      class="csn-channel-suggestion-portrait-item__image"
      :src="channel.picture"
    />
    <div
      class="csn-channel-suggestion-portrait-item__title cs-textstyle-detail-heading"
    >
      {{ channel.title }}
    </div>
    <div
      class="csn-channel-suggestion-portrait-item__speaker cs-textstyle-paragraph"
    >
      {{ channel.speaker }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    viewChannel() {
      this.$router.push({ name: 'channel', params: { id: this.channel.id } });
    },
  },
};
</script>
<style scoped>
.csn-channel-suggestion-portrait-item {
  display: inline-block;
  vertical-align: top;
  color: var(--cs-gray-08);
  padding: 16px;
  padding-top: 0;
  margin-right: -20px;
  --csn-channel-suggestion-portrait-item-size: 120px;
}
.csn-channel-suggestion-portrait-item__image {
  height: var(--csn-channel-suggestion-portrait-item-size);
  width: var(--csn-channel-suggestion-portrait-item-size);
  object-fit: cover;
}
.csn-channel-suggestion-portrait-item__title {
  max-width: var(--csn-channel-suggestion-portrait-item-size);
  white-space: normal;
  padding-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 80px;
}
.csn-channel-suggestion-portrait-item__speaker {
  max-width: var(--csn-channel-suggestion-portrait-item-size);
  white-space: nowrap;
  padding-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--cs-gray-05);
}
</style>
