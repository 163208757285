<template>
	<a v-if="phone" class="csn-phone-link" :href="`tel:${phone}`">{{ phone }}</a>
</template>
<script>
export default {
	props: {
		phone: {
			type: String,
			default: '',
		},
	},
};
</script>
<style scoped></style>
