<template>
  <div>
    <div v-if="$apollo.queries.states.loading" class="csn-spinner__wrapper">
      <cs-spinner />
    </div>
    <cs-select
      v-else
      v-model="selectedValue"
      :options="options"
      placeholder="Select State"
      @input="$emit('input', selectedValue)"
      label="Select your state"
    ></cs-select>
  </div>
</template>

<script>
import ListStates from '@/graphql/general/ListStates.gql';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  apollo: {
    states: {
      query: ListStates,
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  computed: {
    options() {
      let array = [];
      if (this.states) {
        this.states.forEach((element) => {
          array.push({
            label: element.name,
            value: element.id,
          });
        });
      }
      return array;
    },
  },
};
</script>

<style scoped>
.csn-spinner__wrapper {
  height: 78px !important;
  position: relative;
}
.csn-spinner__wrapper >>> .cs-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
