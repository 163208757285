import { render, staticRenderFns } from "./SkeletonStationsGroup.vue?vue&type=template&id=34089a9e&scoped=true&"
var script = {}
import style0 from "./SkeletonStationsGroup.vue?vue&type=style&index=0&id=34089a9e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34089a9e",
  null
  
)

export default component.exports