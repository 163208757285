<template>
  <app-donate-details>
    <div class="cs-textstyle-item-heading csn-donate-details__title">
      Donate by Phone
    </div>
    <div class="cs-textstyle-paragraph csn-donate-details__description">
      <p>
        You may call us to make a one-time donation, or to set up a recurring
        monthly donation for any amount. Please call us at
        <app-phone-link phone="800-357-4226" />
      </p>
    </div>
    <div class="cs-textstyle-item-heading csn-donate-details__title">
      Donate by Mail
    </div>
    <div class="cs-textstyle-paragraph csn-donate-details__description">
      <p>
        Download and print our
        <app-link
          href="https://csnradio.com/wp-content/uploads/2019/12/CSN-Donation-Form-Web.pdf"
          >donation form</app-link
        >. Completed forms can be sent, along with payment in the form of check,
        money order or credit card information, to:
      </p>
      <p>
        CSN International<br />
        PO Box 391<br />
        Twin Falls, ID 83303
      </p>
      <p>
        You can also support CSN by making a donation for our available books,
        videos, and other resources,
        <app-link href="https://store.csnradio.com/"
          >on the CSN store.</app-link
        >
        <!-- Disabled in-app store link due to no store -->
        <!-- <router-link :to="{ name: 'store' }">on the CSN store.</router-link> -->
      </p>
    </div>
    <app-donate-button />
  </app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
import AppPhoneLink from '@/components/general/PhoneLink.vue';
import AppLink from '@/components/general/Link.vue';

export default {
  components: {
    AppDonateDetails,
    AppDonateButton,
    AppPhoneLink,
    AppLink,
  },
};
</script>
