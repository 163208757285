<template>
	<app-donate-details>
		<div class="cs-textstyle-item-heading csn-donate-details__title">
			Subscribe to a monthly recurring donation
		</div>
		<div class="cs-textstyle-paragraph csn-donate-details__description">
			<p>
				The donation subscription will start the same day that you activate it,
				and will continue to run automatically on the same day of the month that
				the subscription was started. If you don't want to donate monthly, you
				can choose a quarterly or annually donation subscription.
			</p>
			<p>
				If you would like to cancel your subscription or if you want to have the
				recurring donation start on a specific date, please call us at
				<app-phone-link phone="800-357-4226" /> to make changes to your
				donation.
			</p>
		</div>
		<app-donate-button title="Subscribe Now" />
	</app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
import AppPhoneLink from '@/components/general/PhoneLink.vue';

export default {
	components: {
		AppDonateDetails,
		AppDonateButton,
		AppPhoneLink,
	},
};
</script>
