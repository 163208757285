<template>
  <div class="csn-show-schedule">
    <div class="ion-padding csn-show-schedule__top-bar-wrapper">
      <div class="csn-show-schedule__top-bar">
        <div
          class="csn-show-schedule__top-bar-shadow csn-show-schedule__top-bar-shadow--left"
        ></div>
        <cs-page-tabs
          class="csn-show-schedule__page-tabs"
          :tabs="tabLabels"
          :value="tabIndex"
          @input="onPageTabChanged"
          align="justify"
          no-border
        />
        <div
          class="csn-show-schedule__top-bar-shadow csn-show-schedule__top-bar-shadow--right"
        ></div>

        <!-- <i
          class="csn-show-schedule__calendar-icon cs-icons-calendar"
          @click="onCalendarClick"
        /> -->
      </div>
    </div>
    <ion-slides
      class="csn-show-schedule__slides"
      ref="scheduleSlides"
      @ionSlideDidChange="onSlideChanged"
      @ionSlidesDidLoad="onSlidesLoaded"
    >
      <ion-slide
        class="csn-show-schedule__slide"
        v-for="(day, $index) in days"
        :key="day.id"
      >
        <div
          :ref="`${$index}-show-list`"
          :class="`${$index}-show-list`"
          class="ion-padding csn-show-schedule__show-list"
          v-if="schedule"
        >
          <!-- Custom key because a show may be displayed on multiple pages if it ends the day after it starts -->
          <app-show-list-item
            v-for="show in dayShows(day.id)"
            :key="`${day.id}-${show.id}-${show.time.ts}`"
            :show="show"
          />
          <cs-empty-state
            v-if="!dayShows(day.id).length"
            class="csn-show-schedule__no-shows"
            description="No shows for this day"
          />
          <app-store-button block />
        </div>
        <cs-spinner v-else />
      </ion-slide>
    </ion-slides>
  </div>
</template>
<script>
import AppShowListItem from '@/components/shows/ShowListItem.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';

import { promiseTimeout } from '@/services/utils';

import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    AppShowListItem,
    AppStoreButton,
  },
  props: {
    channel: {
      type: Object,
      default: null,
    },
    focusNowPlaying: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('shows', {
      allSchedule: 'schedule',
    }),
    ...mapGetters('shows', {
      getChannelSchedule: 'channelSchedule',
    }),
    schedule() {
      if (this.channel) return this.getChannelSchedule(this.channel.id);
      return this.allSchedule;
    },
    dayShows() {
      return (dayId) => {
        if (!this.schedule) return null;
        return this.schedule[dayId];
      };
    },
    tabLabels() {
      return this.days.map((day) => day.title);
    },
  },
  data() {
    return {
      days: getDays(),
      tabIndex: 0,
    };
  },
  mounted() {
    this.$store.dispatch('shows/fetch');
  },
  methods: {
    onPageTabChanged(index) {
      // this.focusTabs(index);
      const slides = this.$refs.scheduleSlides;
      if (!slides) return;
      slides.slideTo(index);
    },
    async onSlideChanged() {
      const slides = this.$refs.scheduleSlides;
      if (!slides) return;
      const slideIndex = await slides.getActiveIndex();
      this.tabIndex = slideIndex;
      // this.focusTabs(slideIndex);
    },
    onCalendarClick() {
      this.scrollToNowPlaying();
    },
    async scrollToNowPlaying(noAnimation) {
      this.tabIndex = 0;
      const slides = this.$refs.scheduleSlides;
      if (!slides) return;
      const waitSlideAnimation = slides.getActiveIndex !== 0;
      slides.slideTo(0);
      if (waitSlideAnimation) await promiseTimeout(200);
      const todayList = this.$refs['0-show-list'];
      if (!todayList || !todayList[0]) return;
      const liveItem = todayList[0].querySelector(
        '.csn-show-list-item[active]'
      );
      if (liveItem) {
        noAnimation
          ? liveItem.scrollIntoView()
          : liveItem.scrollIntoView({ behavior: 'smooth' });
      }
    },
    // TODO Scroll the page tabs into focus if necessary
    // focusTabs(index) {
    // console.log(this.$el, index);
    // if (!this.$el) return;
    // const tabs = this.$el.querySelectorAll('.cs-page-tabs__tab-label');
    // const selectedTab = tabs[index];
    // console.log('tabs', tabs, selectedTab);
    // if (!selectedTab) return;
    // },
    onSlidesLoaded() {
      if (this.focusNowPlaying) this.scrollToNowPlaying('no-animation');
    },
  },
  created() {
    if (this.focusNowPlaying) this.scrollToNowPlaying('no-animation');
  },
  watch: {
    schedule: {
      handler() {
        if (this.focusNowPlaying) this.scrollToNowPlaying('no-animation');
      },
    },
  },
};

const getDays = () => {
  const dayCount = 7;
  const today = new Date().getDay();
  const days = [{ id: today, title: 'Today' }];
  for (let i = 1; i < dayCount; i += 1) {
    const id = (today + i) % 7;
    const title = dayTitles[id];
    days.push({ id, title });
  }
  return days;
};
const dayTitles = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
</script>
<style scoped>
.csn-show-schedule__top-bar-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
.csn-show-schedule__top-bar {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
}
.csn-show-schedule__top-bar-shadow {
  height: 30px;
  width: 1px;
  background-color: var(--csn-gray-primary);
  position: absolute;
  top: 0;
}
.csn-show-schedule__top-bar-shadow--left {
  left: 0;
  box-shadow: 2px 0 8px 6px var(--csn-gray-primary);
}
.csn-show-schedule__top-bar-shadow--right {
  /* right: calc(41px); */
  right: 0;
  box-shadow: -2px 0 8px 6px var(--csn-gray-primary);
}
.csn-show-schedule__calendar-icon {
  font-size: 28px;
  padding: 4px;
  margin-right: -4px;
  color: var(--cs-primary-base);
  z-index: 1;
}
.csn-show-schedule__page-tabs {
  flex: 1;
}
.csn-show-schedule__show-list {
  width: 100%;
  max-height: calc(
    100vh - 68px - 43px - 50px - env(safe-area-inset-top) -
      env(safe-area-inset-bottom)
  );
  padding-bottom: 120px;
  overflow-y: scroll;
}
</style>
