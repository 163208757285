<template>
  <span @click="visitStore">
    <slot />
  </span>
</template>

<script>
import $dialog from '@/services/dialog';
import $iab from '@/services/inappbrowser';

const csnStoreLink = 'https://store.csnradio.com/';

export default {
  methods: {
    async visitStore() {
      const proceed = await $dialog.confirm({
        title: 'You will now be redirected to our store',
        okayLabel: 'Ok',
        cancelLabel: 'Back',
      });
      if (!proceed) return;
      $iab.openExternal(csnStoreLink);
    },
  },
};
</script>
