<template>
  <div class="csn-progress-bar">
    <div class="csn-progress-bar__fill" :style="computedWidth"></div>
  </div>
</template>
<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    computedWidth() {
      let progress = this.progress;
      if (progress > 100) progress = 100;
      if (progress < 0) progress = 0;
      return `width: ${this.progress}%`;
    },
  },
};
</script>
<style scoped>
.csn-progress-bar {
  background-color: var(--cs-gray-03);
}
.csn-progress-bar__fill {
  background-color: var(--cs-primary-base);
  height: 2px;
  width: 0%;
  transition: width 0.2s;
}
</style>
