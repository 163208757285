import * as simpleStorage from '@/services/simple-storage';

const getPrimaryColor = () => {
  return '#2680EB';
};

const isDarkThemeOn = () => {
  return document.body.classList.contains('dark');
};
const isLightThemeOn = () => {
  return document.body.classList.contains('light');
};

const update = () => {
  _checkDarkMode();
};

let _watchDarkModeInitialised = false;
const _initDarkModeWatcher = () => {
  if (_watchDarkModeInitialised) return;
  const darkMediaQuery = '(prefers-color-scheme: dark)';
  const mediaQueryList = window.matchMedia(darkMediaQuery);
  mediaQueryList.addEventListener('change', _checkDarkMode);
  _checkDarkMode();
  document.addEventListener('resume', _checkDarkMode);
};

const _checkDarkMode = async () => {
  const theme = await simpleStorage.getItem('theme');
  if (!theme || theme === 'auto') {
    const darkMediaQuery = '(prefers-color-scheme: dark)';
    const mediaQueryList = window.matchMedia(darkMediaQuery);
    mediaQueryList.matches ? _applyDarkMode() : _applyLightMode();
  } else {
    theme === 'dark' ? _applyDarkMode() : _applyLightMode();
  }
};

const _applyDarkMode = () => {
  if (!isDarkThemeOn()) {
    document.body.classList.remove('light');
    document.body.classList.add('dark');
    _themeIOS(true);
    _themeAndroid(true);
  }
};

const _applyLightMode = () => {
  if (!isLightThemeOn()) {
    document.body.classList.remove('dark');
    document.body.classList.add('light');
    _themeIOS(false);
    _themeAndroid(false);
  }
};

const _themeIOS = (dark) => {
  if (!window.cordova || window.cordova.platformId != 'ios') return;

  // Theme Status Bar
  if (window.StatusBar) {
    if (dark && window.StatusBar.styleLightContent)
      window.StatusBar.styleLightContent();
    if (!dark && window.StatusBar.styleDefault) window.StatusBar.styleDefault();
    if (window.StatusBar.overlaysWebView)
      window.StatusBar.overlaysWebView(true);
  }

  // Theme keyboard
  const theme = dark ? 'dark' : 'light';
  window.cordova &&
    window.cordova.platformId === 'ios' &&
    window.Keyboard &&
    window.Keyboard.setKeyboardStyle &&
    window.Keyboard.setKeyboardStyle(theme);
};

let _statusBarPage = '';
const _themeAndroid = (dark) => {
  if (!window.cordova || window.cordova.platformId != 'android') return;
  console.log('dark theme android ', dark);
  // Set statusbar to be dark with light icons and prevents statusbar overlaying content
  // if (window.StatusBar) {
  // if (dark && window.StatusBar.styleLightContent) window.StatusBar.styleLightContent();
  // if (!dark && window.StatusBar.styleDefault) window.StatusBar.styleDefault();
  // if (window.StatusBar.overlaysWebView)
  //   window.StatusBar.overlaysWebView(true);

  // Currently Android doesn't seem to respect env(safe-inset-top) css property
  // so i've disabled the above lines and use the following:
  // Prevent status bar overlaying content
  // if (window.StatusBar.overlaysWebView)
  //   window.StatusBar.overlaysWebView(false);
  // if (dark) {
  //   if (window.StatusBar.styleLightContent)
  //     window.StatusBar.styleLightContent();
  //   if (window.StatusBar.backgroundColorByHexString) {
  //     let statusbarColor = '#13141B';
  //     if (_statusBarPage === 'login') statusbarColor = '#0669f9';
  //     if (_statusBarPage === 'overlay') statusbarColor = '#030303';
  //     window.StatusBar.backgroundColorByHexString(statusbarColor);
  //   }
  // } else {
  //   if (window.StatusBar.styleDefault) window.StatusBar.styleDefault();
  //   if (window.StatusBar.backgroundColorByHexString) {
  //     let statusbarColor = '#FFFFFF';
  //     if (_statusBarPage === 'login') statusbarColor = '#0669f9';
  //     if (_statusBarPage === 'overlay') statusbarColor = '#333333';
  //     window.StatusBar.backgroundColorByHexString(statusbarColor);
  //   }
  // }
  // }
};
const applyDefault = () => {
  if (_statusBarPage === '') return;
  _statusBarPage = '';
  _themeAndroid(isDarkThemeOn());
  _themeIOS(isDarkThemeOn());
};
const applyLogin = () => {
  if (_statusBarPage === 'login') return;
  _statusBarPage = 'login';
  _themeAndroid(true);
  _themeIOS(true);
};
const applyOverlay = () => {
  if (_statusBarPage === 'overlay') return;
  _statusBarPage = 'overlay';
  _themeAndroid(isDarkThemeOn());
  _themeIOS(isDarkThemeOn());
};

if (window.cordova) {
  document.addEventListener('deviceready', _initDarkModeWatcher);
} else {
  _initDarkModeWatcher();
}

export default {
  getPrimaryColor,
  isDarkThemeOn,
  isLightThemeOn,
  update,
  applyDefault,
  applyLogin,
  applyOverlay,
};
