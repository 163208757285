<template>
	<app-donate-details>
		<div class="cs-textstyle-item-heading csn-donate-details__title">
			Make a one-time donation for any amount
		</div>
		<div class="cs-textstyle-paragraph csn-donate-details__description">
			<p>Anything you can contribute is appreciated.</p>
		</div>
		<app-donate-button />
	</app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
export default {
	components: {
		AppDonateDetails,
		AppDonateButton,
	},
};
</script>
