<template>
  <span class="csn-play-pause-button" @click.stop="onPlayClick">
    <ion-spinner
      v-if="isLoading"
      class="cs-play-pause-button__loading"
      name="circles"
    />
    <i v-else-if="isPlaying" class="cs-icons-pause2-filled" />
    <i v-else class="cs-icons-play-filled" />
  </span>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('audio', {
      isPlaying: 'isPlaying',
      isLoading: 'isLoading',
    }),
  },
  methods: {
    onPlayClick() {
      if (this.isLoading) return;
      if (this.isPlaying) return this.$store.dispatch('audio/pause');
      return this.$store.dispatch('audio/play');
    },
  },
};
</script>
<style scoped>
.cs-play-pause-button__loading {
  color: var(--cs-white);
}
.csn-play-pause-button {
  color: var(--cs-white);
  font-size: 20px;
  background-color: var(--cs-primary-base);
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
</style>
