<template>
  <ion-page>
    <app-main-header title="Menu" />
    <ion-content class="csn-menu">
      <ion-list>
        <cs-navigation-page class="csn-navigation-page">
          <cs-profile
            v-if="user"
            class="csn-menu__profile"
            slot="profile"
            :picture="user.picture"
            :name="user.is_guest ? 'Guest User' : user.name || 'No Name'"
            :location="stateName"
            avatar-position="left"
            avatar-size="medium"
            @click="showEditProfileModal = true"
          />
          <cs-navigation-page-link
            slot="link"
            title="Station Finder"
            icon="cs-icons-live-filled"
            @click="goto({ name: 'stations' })"
          />
          <cs-navigation-page-link
            slot="link"
            title="Donate"
            icon="cs-icons-coins-filled"
            @click="goto({ name: 'donate' })"
          />
          <cs-navigation-page-link
            slot="link"
            title="Contact Us"
            icon="cs-icons-phone-filled"
            @click="goto({ name: 'contact-us' })"
          />
          <cs-navigation-page-link
            slot="link"
            title="About Us"
            icon="cs-icons-marker-filled"
            @click="goto({ name: 'about-us' })"
          />
          <cs-navigation-page-link
            slot="link"
            title="Resources"
            icon="cs-icons-library-filled"
            @click="visitStore"
          />
          <cs-navigation-page-link
            slot="link"
            title="Log Out"
            icon="cs-icons-logout-filled"
            @click="logout"
          />
        </cs-navigation-page>
        <div class="ion-padding">
          <app-dark-mode-toggle />
          <app-csn-social-buttons />
        </div>
        <app-privacy-terms />
      </ion-list>
    </ion-content>
    <app-edit-profile-modal
      :show="showEditProfileModal"
      @close="showEditProfileModal = false"
    />
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppCsnSocialButtons from '@/components/general/CsnSocialButtons.vue';
import AppPrivacyTerms from '@/components/general/PrivacyTerms.vue';

import AppEditProfileModal from '@/components/modals/EditProfileModal.vue';

import AppDarkModeToggle from '@/components/menu/DarkModeToggle.vue';

import { mapState } from 'vuex';

import $dialog from '@/services/dialog';
import $iab from '@/services/inappbrowser';

const csnStoreLink = 'https://store.csnradio.com/';

export default {
  components: {
    AppMainHeader,
    AppCsnSocialButtons,
    AppPrivacyTerms,
    AppEditProfileModal,
    AppDarkModeToggle,
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    stateName() {
      if (!this.user || !this.user.state) return '';
      return this.user.state.name;
    },
  },
  data() {
    return {
      showEditProfileModal: false,
    };
  },
  methods: {
    async visitStore() {
      const proceed = await $dialog.confirm({
        title: 'You will now be redirected to our store',
        okayLabel: 'Ok',
        cancelLabel: 'Back',
      });
      if (!proceed) return;
      $iab.openExternal(csnStoreLink);
    },
    async logout() {
      const confirmed = await $dialog.confirm({
        title: 'Logout',
        message: 'Are you sure you wish to logout?',
        okayLabel: 'Logout',
      });
      if (!confirmed) return;
      this.$store.dispatch('auth/logout');
    },
    goto(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.csn-menu .csn-menu__profile {
  margin: 0 16px;
  --cs-profile-background: var(--csn-gray-secondary);
  border-radius: 0;
  flex: 1;
}
.csn-menu .csn-navigation-page {
  background-color: var(--csn-gray-primary);
}
.csn-menu >>> .cs-navigation-page__line-wrapper {
  display: none;
}
</style>
