<template>
  <div class="csn-channel-list-item" @click="viewChannel">
    <div class="csn-channel-list-item__top-bar">
      <img class="csn-channel-list-item__image" :src="channel.picture" />
      <div class="csn-channel-list-item__titles">
        <div class="csn-channel-list-item__title cs-textstyle-detail-heading">
          {{ channel.title }}
        </div>
        <div
          class="csn-channel-list-item__speaker cs-textstyle-paragraph-small"
        >
          {{ channel.speaker }}
        </div>
      </div>
      <app-channel-follow-button :channel="channel" />
    </div>
  </div>
</template>
<script>
import AppChannelFollowButton from '@/components/channels/ChannelFollowButton.vue';

export default {
  components: {
    AppChannelFollowButton,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    viewChannel() {
      this.$router.push({ name: 'channel', params: { id: this.channel.id } });
    },
  },
};
</script>
<style scoped>
.csn-channel-list-item {
  padding: 16px;
  background-color: var(--csn-gray-secondary);
  color: var(--cs-gray-08);
  margin-top: 16px;
}
.csn-channel-list-item__top-bar {
  display: flex;
  gap: 10px;
}
.csn-channel-list-item__image {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.csn-channel-list-item__titles {
  flex: 1;
  min-width: 0;
  align-self: center;
}
.csn-channel-list-item__title,
.csn-channel-list-item__speaker {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.csn-channel-list-item__speaker {
  color: var(--cs-gray-05);
}
</style>
