<template>
  <div class="csn-store-button">
    <app-store-link-wrapper>
      <cs-button corners="rounded" v-bind="$attrs">
        <i :class="icon" />
        <span class="csn-store__button-text">{{ title }}</span>
      </cs-button>
    </app-store-link-wrapper>
  </div>
</template>
<script>
import AppStoreLinkWrapper from '@/components/store/StoreLinkWrapper.vue';

export default {
  components: {
    AppStoreLinkWrapper,
  },
  props: {
    title: {
      type: String,
      default: 'Resources',
    },
    icon: {
      type: String,
      default: 'cs-icons-library',
    },
  },
};
</script>
<style scoped>
.csn-store-button {
  margin-top: 32px;
  text-align: center;
}
</style>
