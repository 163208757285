<template>
	<app-donate-details>
		<div class="cs-textstyle-item-heading csn-donate-details__title">
			Donate Real or Personal Property
		</div>
		<div class="cs-textstyle-paragraph csn-donate-details__description">
			<p>
				CSN also accepts donations of real and/or personal property, such as
				cash, stocks, bonds, real/personal property, paintings and antiques of
				value and more.
			</p>
		</div>
		<app-donate-button />
	</app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
export default {
	components: {
		AppDonateDetails,
		AppDonateButton,
	},
};
</script>
