/* eslint-disable */
const getStorageItem = (key) => {
  return new Promise(function (resolve, reject) {
    if (window.cordova) {
      document.addEventListener('deviceready', () => {
        if (window.NativeStorage) {
          NativeStorage.getItem(key, resolve, () => resolve(null));
        } else {
          getLocalStorageItem(key, resolve);
        }
      });
    } else {
      getLocalStorageItem(key, resolve);
    }
  }).catch(function () {
    return null;
  });
};

const getLocalStorageItem = (key, resolve) => {
  const stringItem = localStorage.getItem(key);
  try {
    const jsonItem = JSON.parse(stringItem);
    resolve(jsonItem);
  } catch (e) {
    resolve(stringItem);
  }
};

const setStorageItem = (key, value) => {
  return new Promise(function (resolve, reject) {
    localStorage.setItem(key, value);
    if (window.cordova) {
      document.addEventListener('deviceready', () => {
        if (window.NativeStorage) {
          NativeStorage.setItem(key, value, resolve, reject);
        }
      });
    } else {
      resolve(value);
    }
  }).catch(function () {
    return null;
  });
};

const removeStorageItem = (key) => {
  return new Promise(function (resolve, reject) {
    localStorage.removeItem(key);
    if (window.cordova) {
      document.addEventListener('deviceready', () => {
        if (window.NativeStorage) {
          NativeStorage.remove(key, resolve, reject);
        }
      });
    } else {
      resolve(true);
    }
  }).catch(function () {
    return null;
  });
};

export const getItem = getStorageItem;
export const setItem = setStorageItem;
export const removeItem = removeStorageItem;
