<template>
  <div class="csn-onboarding-slide">
    <img class="csn-onboarding-slide__picture" :src="picture" alt="" />
    <div class="cs-textstyle-page-subtitle csn-onboarding-slide__title">
      {{ title }}
    </div>
    <div
      class="cs-textstyle-informative-paragraph csn-onboarding-slide__description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    picture: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.csn-onboarding-slide__title {
  margin-bottom: 8px;
  margin-top: 35px;
  color: var(--cs-white);
}
.csn-onboarding-slide__description {
  color: var(--cs-white);
  padding: 0px 60px;
  line-height: 30px;
  text-align: center;
}
</style>
