<template>
  <div class="floating-audio-player" v-show="audioInitialised">
    <transition name="fade-in-out">
      <app-floating-audio-button
        v-if="playingShow"
        @click="floatingButtonClick"
        :animated="audioPlaying"
        class="floating-audio-player__floating-button"
      />
    </transition>
    <transition name="slide-in-out">
      <div v-if="playerOpen" class="floating-audio-player__content ion-padding">
        <template v-if="playingShow">
          <app-show-list-item
            v-if="playingShow"
            :show="playingShow"
            hide-live-banner
            class="floating-audio-player__show-list-item"
          />
          <app-progress-bar
            class="floating-audio-player__progress"
            :progress="progressOfShow"
          />
        </template>
        <template v-else-if="audioLoading">
          <app-skeleton-show-list-item />
        </template>
        <template v-else>
          <div class="floating-audio-player__no-show">
            <div class="cs-textstyle-paragraph">No show information found</div>
            <app-play-pause-button />
          </div>
        </template>

        <div class="floating-audio-player__bottom-row">
          <div class="cs-textstyle-tags floating-audio-player__time">
            <span v-if="playingShow">{{ showTime }}</span>
          </div>
          <div
            class="cs-textstyle-tags floating-audio-player__hide"
            @click="hidePlayer"
          >
            Hide
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AppFloatingAudioButton from '@/components/audio/FloatingAudioButton.vue';
import AppPlayPauseButton from '@/components/audio/PlayPauseButton.vue';
import AppShowListItem from '@/components/shows/ShowListItem.vue';
import AppProgressBar from '@/components/general/ProgressBar.vue';
import AppSkeletonShowListItem from '@/components/skeletons/SkeletonShowListItem.vue';
import { mapState } from 'vuex';

export default {
  components: {
    AppFloatingAudioButton,
    AppPlayPauseButton,
    AppShowListItem,
    AppProgressBar,
    AppSkeletonShowListItem,
  },
  computed: {
    ...mapState('audio', {
      audioPlaying: 'isPlaying',
      audioProgress: 'audioProgress',
      audioLoading: 'audioLoading',
    }),
    ...mapState('shows', {
      playingShow: 'liveShow',
      nextShows: 'nextShows',
    }),
    upcomingShow() {
      if (!this.nextShows || !this.nextShows.length) return;
      return this.nextShows[0];
    },
    lengthOfShow() {
      if (!this.playingShow || !this.upcomingShow) return;
      if (!this.playingShow.time || !this.upcomingShow.time) return;
      return this.upcomingShow.time.valueOf() - this.playingShow.time.valueOf();
    },
    timePassedOfShow() {
      if (!this.playingShow) return;
      if (!this.playingShow.time) return;
      return this.now - this.playingShow.time.valueOf();
    },
    progressOfShow() {
      if (!this.lengthOfShow || !this.timePassedOfShow) return 0;
      return (this.timePassedOfShow / this.lengthOfShow) * 100;
    },
    showTime() {
      if (!this.lengthOfShow || !this.timePassedOfShow) return '';
      const displayLengthMinutes = Math.ceil(this.lengthOfShow / 1000 / 60);
      const progressSeconds = Math.round(this.timePassedOfShow / 1000);
      const displaySeconds = Math.round(progressSeconds % 60);
      const formattedSeconds =
        displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds;
      const displayMinutes = Math.floor(progressSeconds / 60);
      return `${displayMinutes}:${formattedSeconds} / ${displayLengthMinutes} Min`;
    },
  },
  data() {
    return {
      now: Date.now(),
      playerOpen: false,
      audioInitialised: true,
      timer: null,
    };
  },
  mounted() {
    this.$store.dispatch('shows/fetch');
    this.startTimer();
  },
  methods: {
    startTimer() {
      if (this.timer) return;
      this.timer = setInterval(() => {
        this.now = Date.now();
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    openPlayer() {
      this.playerOpen = true;
      this.$store.dispatch('shows/checkNowPlaying');
    },
    hidePlayer() {
      this.playerOpen = false;
    },
    floatingButtonClick() {
      this.openPlayer();
      if (!this.audioPlaying) this.$store.dispatch('audio/toggle');
    },
  },
  watch: {
    audioPlaying: {
      immediate: true,
      handler() {
        this.audioPlaying ? this.startTimer() : this.stopTimer();
      },
    },
    progressOfShow() {
      if (this.progressOfShow >= 99) {
        this.$store.dispatch('shows/checkNowPlaying');
      }
    },
  },
};
</script>
<style scoped>
/* Floating button */
.floating-audio-player__floating-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

/* Floating player */
.floating-audio-player {
  position: fixed;
  bottom: calc(51px + env(safe-area-inset-bottom));
  left: 0;
  right: 0;
}
.floating-audio-player__content {
  background-color: var(--csn-gray-primary);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.dark .floating-audio-player__content {
  background-color: var(--csn-gray-secondary);
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.55);
}

/* Show item details */
.floating-audio-player__show-list-item {
  margin: 0;
}
.floating-audio-player__show-list-item >>> .csn-show-list-item__content {
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}

/* Progress bar */
.floating-audio-player__progress {
  margin: 8px 0;
}

.floating-audio-player__no-show {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  color: var(--cs-gray-08);
}

/* Bottom bar with time and hide button */
.floating-audio-player__bottom-row {
  display: flex;
  justify-content: space-between;
}
.floating-audio-player__time,
.floating-audio-player__hide {
  color: var(--cs-gray-04);
  font-weight: bold;
  padding: 8px;
  margin: -8px;
}

/* Animations */
.fade-in-out-enter-active,
.fade-in-out-leave-active {
  transition: opacity 0.2s;
}
.fade-in-out-enter,
.fade-in-out-leave-to {
  opacity: 0;
}
.slide-in-out-enter-active,
.slide-in-out-leave-active {
  transition: transform 0.2s;
}
.slide-in-out-enter,
.slide-in-out-leave-to {
  transform: translate3d(0, calc(150% + env(safe-area-inset-bottom)), 0);
}
</style>
