<template>
  <div>
    <i
      class="cs-icons-notification-filled notification-icon"
      :class="{ 'notification-icon--active': newNotifications }"
      ><span class="blue-dot" v-show="newNotifications"></span
    ></i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('notifications', {
      newNotifications: 'newNotifications',
    }),
  },
  async mounted() {
    this.$store.dispatch('notifications/checkNotifications');
  },
};
</script>

<style scoped>
.notification-icon {
  color: var(--cs-gray-04);
  position: relative;
  cursor: pointer;
  font-size: 24px;
}
.dark .notification-icon.notification-icon--active {
  color: var(--cs-white);
}
.blue-dot {
  width: 7px;
  height: 7px;
  background: var(--cs-primary-base);
  position: absolute;
  border-radius: 50%;
  right: -3px;
}
</style>
