<template>
  <div class="csn-notification-list">
    <div class="cs-textstyle-tags csn-notification-list__title">
      {{ title }}
    </div>
    <notification-item
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default {
  components: { NotificationItem },
  props: {
    notifications: {
      type: Array,
      default: null,
    },
  },
  computed: {
    title() {
      const firstNotification = this.notifications
        ? this.notifications[0]
        : null;
      if (!firstNotification) return '';

      const firstNotificationDate = new Date(firstNotification.created_at);
      const firstMonthYear = getMonthYear(firstNotificationDate);
      const nowMonthYear = getMonthYear(new Date());
      if (nowMonthYear === firstMonthYear) return 'This Month';

      const monthString = months[firstNotificationDate.getMonth()];
      const nowYear = new Date().getFullYear();
      const firstNotificationYear = firstNotificationDate.getFullYear();
      const yearString =
        nowYear === firstNotificationYear ? '' : ` ${firstNotificationYear}`;
      return `${monthString}${yearString}`;
    },
  },
};

const getMonthYear = (dateValue) => {
  const date = new Date(dateValue);
  return `${date.getMonth()}-${date.getFullYear()}`;
};
</script>

<style scoped>
.csn-notification-list {
  margin-bottom: 24px;
}
.csn-notification-list__title {
  margin-bottom: 24px;
  color: var(--cs-gray-04);
  text-align: center;
  margin-top: 24px;
}
.csn-notification-list__title:first-of-type {
  margin-top: 0px;
}
</style>
