<template>
  <div>
    <ion-tabs>
      <!-- Tab routers -->
      <ion-tab tab="/tabs/home">
        <ion-vue-router name="home"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/tabs/channels">
        <ion-vue-router name="channels"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/tabs/live">
        <ion-vue-router name="live"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/tabs/stations">
        <ion-vue-router name="stations"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/tabs/donate">
        <ion-vue-router name="donate"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/tabs/menu">
        <ion-vue-router name="menu"></ion-vue-router>
      </ion-tab>

      <!-- No tabs route is for routes with the meta.notabs property -->
      <!-- There were issues trying to load those pages in the ion-vue-router in App.vue, so they load here -->
      <ion-tab tab="/notabs">
        <ion-vue-router name="notabs"></ion-vue-router>
      </ion-tab>

      <!-- Tab Icons and Labels -->
      <ion-tab-bar slot="bottom" v-show="showTabs">
        <ion-tab-button tab="/tabs/home">
          <i class="cs-icons-home"></i>
        </ion-tab-button>
        <ion-tab-button tab="/tabs/live">
          <i class="cs-icons-audio"></i>
        </ion-tab-button>
        <ion-tab-button tab="/tabs/channels">
          <i class="cs-icons-bookmark"></i>
          <!-- <i class="cs-icons-channels"></i> -->
        </ion-tab-button>
        <ion-tab-button tab="/tabs/stations">
          <i class="cs-icons-live"></i>
        </ion-tab-button>
        <ion-tab-button tab="/tabs/donate">
          <i class="cs-icons-coins"></i>
        </ion-tab-button>
        <ion-tab-button tab="/tabs/menu">
          <i class="cs-icons-more"></i>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

    <app-floating-audio-player v-show="showTabs" />
  </div>
</template>
<script>
import AppFloatingAudioPlayer from '@/components/audio/FloatingAudioPlayer.vue';

export default {
  components: {
    AppFloatingAudioPlayer,
  },
  data() {
    return {
      showTabs: false,
    };
  },
  methods: {
    // Checks whether or not to show the tabs when navigating between views
    checkTabs() {
      const meta = this.$router.currentRoute.meta;
      this.showTabs = !meta.notabs;
    },
  },
  created() {
    this.checkTabs();
    this.$router.afterEach(this.checkTabs);
  },
};
</script>
<style scoped>
ion-tab-button i {
  font-size: 2.6em;
}
ion-tab-bar {
  /* --border: none; */
  --background: var(--cs-gray-00);
  filter: drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.1));
  border-top: solid 1px rgba(90, 90, 106, 0.29);
}
.dark ion-tab-bar {
  --background: var(--csn-gray-secondary);
}
.dark ion-tab-button {
  --color-focused: var(--cs-white);
  --color-selected: var(--cs-white);
}
</style>
