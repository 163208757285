<template>
  <ion-page>
    <app-main-header title="Home">
      <template v-slot:right v-if="userId">
        <app-notification-icon @click="goToNotificationPage" />
      </template>
    </app-main-header>
    <ion-content>
      <ion-list>
        <app-header-ads />
        <app-live-shows-list class="csn-home--horizontal-padding" />
        <app-push-notifications-button block class="csn-home--padding" />
        <app-upcoming-shows-list class="csn-home--horizontal-padding" />
        <app-popular-channels-horizontal-list />
        <app-donate-button block class="csn-home--horizontal-padding" />
        <app-store-button block class="csn-home--horizontal-padding" />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppHeaderAds from '@/components/home/HeaderAds.vue';
import AppLiveShowsList from '@/components/home/LiveShowsList.vue';
import AppUpcomingShowsList from '@/components/home/UpcomingShowsList.vue';
import AppPopularChannelsHorizontalList from '@/components/home/PopularChannelsHorizontalList.vue';
import AppNotificationIcon from '@/components/notifications/NotificationIcon.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';
import AppPushNotificationsButton from '@/components/notifications/PushNotificationsButton.vue';

import ListHeaderAds from '@/graphql/home/ListHeaderAds.gql';

import { mapGetters } from 'vuex';

export default {
  components: {
    AppMainHeader,
    AppHeaderAds,
    AppLiveShowsList,
    AppUpcomingShowsList,
    AppPopularChannelsHorizontalList,
    AppDonateButton,
    AppStoreButton,
    AppNotificationIcon,
    AppPushNotificationsButton,
  },
  apollo: {
    header_ads: {
      query: ListHeaderAds,
    },
  },
  methods: {
    goToNotificationPage() {
      this.$router.push('/tabs/home/notifications');
    },
  },
  computed: {
    ...mapGetters('auth', {
      userId: 'userId',
    }),
  },
};
</script>

<style scoped>
.csn-home--horizontal-padding {
  padding: 0 16px;
}
.csn-home--padding {
  padding: 16px;
}
</style>
