<template>
  <ion-page>
    <app-main-header
      title="My Programs"
      button-right="All"
      @button-right="viewAllChannels"
    />
    <ion-content>
      <ion-list
        class="ion-padding"
        v-if="$apollo.queries.user_channels.loading"
      >
        <skeleton w="100%" h="40px" />
        <skeleton-channel-list-item v-for="i in 6" :key="i" />
      </ion-list>
      <ion-list class="ion-padding" v-else>
        <template v-if="channels">
          <cs-search-bar v-model="search" placeholder="Search programs" />
          <app-channel-list-item
            v-for="channel in filteredChannels"
            :key="channel.id"
            :channel="channel"
          />
        </template>
        <cs-empty-state
          v-if="showEmptyState"
          description="No programs found.  Find new programs to follow in the All section (top-right button)."
        />
        <cs-empty-state
          v-if="errorMessage"
          title="Error loading programs"
          :description="errorMessage"
        >
          <cs-button size="small" corners="rounded" @click="refresh"
            >Retry</cs-button
          >
        </cs-empty-state>
        <app-store-button block />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppChannelListItem from '@/components/channels/ChannelListItem.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';
import SkeletonChannelListItem from '@/components/skeletons/SkeletonChannelListItem.vue';

// GQL
import ListUserChannels from '@/graphql/channels/ListUserChannels.gql';

import { mapGetters } from 'vuex';

export default {
  components: {
    AppMainHeader,
    AppChannelListItem,
    AppStoreButton,
    SkeletonChannelListItem,
  },
  apollo: {
    user_channels: {
      query: ListUserChannels,
      variables() {
        return {
          userId: this.userId,
        };
      },
      manual: true,
      result({ data, loading }) {
        if (!loading && data) {
          this.setChannels(data.user_channels);
        }
      },
      error: (error, vm) => {
        vm.handleError(error);
        return false;
      },
    },
  },
  computed: {
    ...mapGetters('auth', {
      userId: 'userId',
    }),
    showEmptyState() {
      return this.channels && this.filteredChannels.length === 0;
    },
    filteredChannels() {
      const trimmed = this.search.trim();
      if (!trimmed) return this.channels;

      const searchTerms = trimmed.split(' ').map((term) => term.toLowerCase());
      if (searchTerms.length < 1) return this.channels;

      const filteredChannels = this.channels.filter((channel) => {
        if (matchSearchProp(channel.title, searchTerms)) return true;
        if (matchSearchProp(channel.speaker, searchTerms)) return true;
        return false;
      });
      return filteredChannels;
    },
  },
  data() {
    return {
      search: '',
      errorMessage: null,
      channels: null,
    };
  },
  mounted() {
    if (this.channels) this.refresh();
  },
  methods: {
    viewAllChannels() {
      this.$router.push({ name: 'channels-all' });
    },
    refresh() {
      this.errorMessage = null;
      this.$apollo.queries.user_channels.refetch();
    },
    handleError(error) {
      this.errorMessage = error.message;
    },
    setChannels(userChannels) {
      this.channels = userChannels.map((user_channel) => {
        return {
          ...user_channel.channel,
        };
      });
      const channelIds = this.channels.map((channel) => channel.id);
      this.$store.dispatch('follows/setFollowIds', channelIds);
    },
  },
};

const matchSearchProp = (prop, searchTerms) => {
  const formattedProp = (prop || '').toLowerCase();
  const matches = searchTerms.filter((term) => {
    return formattedProp.indexOf(term) > -1;
  });
  return matches.length === searchTerms.length;
};
</script>

<style scoped></style>
