<template>
  <div class="csn-show-list-item" :active="isNow" @click="viewChannel">
    <app-live-tag
      v-if="isNow && !hideLiveBanner"
      class="csn-show-list-item__live"
    />
    <div class="csn-show-list-item__content">
      <div v-if="channel" class="csn-show-list-item__image-holder">
        <img class="csn-show-list-item__image" :src="channel.picture" />
      </div>
      <div v-if="channel" class="csn-show-list-item__titles">
        <div class="csn-show-list-item__title cs-textstyle-detail-heading">
          {{ channel.title }}
        </div>
        <div class="csn-show-list-item__subtitle cs-textstyle-paragraph">
          {{ channel.speaker }}
        </div>
      </div>
      <div class="csn-show-list-item__time-date" v-if="!isNow">
        <div class="csn-show-list-item__time">
          <i class="csn-show-list-item__time-icon cs-icons-clock" />
          <span
            class="csn-show-list-item__time-text cs-textstyle-paragraph-small"
            >{{ showTime }}</span
          >
        </div>
        <div
          v-if="showDate"
          class="csn-show-list-item__date csn-show-list-item__time-text cs-textstyle-paragraph-small"
        >
          {{ showDate }}
        </div>
      </div>
      <div class="csn-show-list-item__play" v-if="isNow">
        <app-play-pause-button />
      </div>
    </div>
  </div>
</template>
<script>
import AppLiveTag from '@/components/general/LiveTag.vue';
import AppPlayPauseButton from '@/components/audio/PlayPauseButton.vue';
import { mapGetters } from 'vuex';
const { DateTime } = require('luxon');

export default {
  components: {
    AppLiveTag,
    AppPlayPauseButton,
  },
  props: {
    show: {
      type: Object,
      required: true,
    },
    hideLiveBanner: {
      type: Boolean,
      default: false,
    },
    disableViewChannelClick: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('shows', {
      isShowLive: 'isShowLive',
    }),
    channel() {
      return this.show.channel;
    },
    isNow() {
      return this.isShowLive(this.show);
    },
    showTime() {
      if (!this.show) return '';
      const showTime = this.show.time.toFormat('h:mm a');
      return showTime;
    },
    showDate() {
      if (!this.show) return '';
      const showDate = this.show.time;
      const nowDate = DateTime.local();
      if (nowDate.toFormat('d-M-yyyy') === showDate.toFormat('d-M-yyyy')) {
        return '';
      } else if (nowDate.toFormat('M-yyyy') === showDate.toFormat('M-yyyy')) {
        return showDate.toFormat('d MMM');
      }
      return showDate.toFormat('d MMM yyyy');
    },
  },
  methods: {
    viewChannel() {
      if (this.disableViewChannelClick) return;
      if (!this.channel) return;
      if (this.$route.name === 'channel') return;
      this.$router.push({ name: 'channel', params: { id: this.channel.id } });
    },
  },
};
</script>
<style scoped>
.csn-show-list-item {
  margin-top: 8px;
  text-align: left;
  position: relative;
}
.csn-show-list-item__live {
  position: absolute;
  top: -4px;
  left: 0px;
}
.csn-show-list-item__content {
  padding: 12px;
  background-color: var(--csn-gray-secondary);
  display: flex;
  gap: 12px;
}
.csn-show-list-item__image-holder {
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
}
.csn-show-list-item__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.csn-show-list-item__titles {
  min-width: 0;
  flex: 1;
  align-self: center;
}
.csn-show-list-item__title {
  color: var(--cs-gray-07);
}
.csn-show-list-item__subtitle {
  color: var(--cs-gray-05);
}
.csn-show-list-item__time-date {
  color: var(--cs-gray-05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}
.csn-show-list-item__time {
  display: flex;
  gap: 4px;
  align-items: center;
}
.csn-show-list-item__date {
}
</style>
