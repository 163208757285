// Easy JS functions to show/hide full-screen Ionic loading spinner with optional message

let loadingSpinner = null;

// All the opening code is because sometimes api calls are super fast and we
//  attempt to hide the spinner before it's showing which means it shows and never hides
let opening = false;

function show(options) {
  options = options || {};
  if (loadingSpinner) {
    loadingSpinner.message = options.message || '';
    return;
  }
  opening = true;

  loadingSpinner = document.createElement('ion-loading');
  loadingSpinner.message = options.message;
  document.body.appendChild(loadingSpinner);
  setTimeout(function () {
    loadingSpinner.present();
    opening = false;
  });
}

function hide() {
  if (opening) {
    setTimeout(hide, 100);
  } else {
    loadingSpinner && loadingSpinner.dismiss();
    loadingSpinner = null;
  }
}

export default {
  show: show,
  hide: hide,
};
