<template>
  <ion-page>
    <app-main-header title="Station Finder" />
    <ion-content>
      <ion-list class="ion-padding" v-if="$apollo.queries.states.loading">
        <skeleton w="100%" h="40px" />

        <skeleton-stations-group v-for="i in 6" :key="i" />
      </ion-list>
      <ion-list class="ion-padding" v-else>
        <template v-if="states">
          <cs-search-bar
            v-model="search"
            placeholder="Search by state, city, or call sign"
          />
          <template v-if="userFilteredState">
            <cs-category-heading title="YOUR STATE" />
            <div class="csn-station-list-group">
              <div class="cs-textstyle-item-heading">
                {{ userFilteredState.name }}
              </div>
              <app-station-list-item
                v-for="city in userFilteredState.filteredCities"
                :key="city.id"
                :city="city"
                :stations="city.filteredStations"
              />
            </div>
          </template>

          <cs-category-heading title="ALL STATES" />
          <div
            v-for="state in filteredStates"
            :key="state.id"
            :state="state"
            class="csn-station-list-group"
          >
            <div class="cs-textstyle-item-heading">
              {{ state.name }}&nbsp;<cs-badge
                v-if="state.id === user.state_id"
                variant="secondary"
                text="YOUR STATE"
              ></cs-badge>
            </div>
            <app-station-list-item
              v-for="city in state.filteredCities"
              :key="city.id"
              :city="city"
              :stations="city.filteredStations"
            />
          </div>
        </template>
        <cs-empty-state v-if="showEmptyState" description="No stations found.">
        </cs-empty-state>
        <cs-empty-state
          v-if="errorMessage"
          title="Error loading stations"
          :description="errorMessage"
        >
          <cs-button size="small" corners="rounded" @click="refresh"
            >Retry</cs-button
          >
        </cs-empty-state>
        <app-store-button block />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppStationListItem from '@/components/stations/StationListItem.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';
import SkeletonStationsGroup from '@/components/skeletons/SkeletonStationsGroup.vue';
import { mapGetters } from 'vuex';

// GQL
import ListStationsByState from '@/graphql/stations/ListStationsByState.gql';

export default {
  components: {
    AppMainHeader,
    AppStationListItem,
    AppStoreButton,
    SkeletonStationsGroup,
  },
  apollo: {
    states: {
      query: ListStationsByState,
      result({ data, loading }) {
        if (!loading && data) {
          this.setStates(data.states);
        }
      },
      error: (error, vm) => {
        vm.handleError(error);
        return false;
      },
    },
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    showEmptyState() {
      return this.states && this.filteredStates.length === 0;
    },
    filteredStates() {
      // Create a default list of all the search results to return
      // Note that we are filtering out cities with no stations and states with no cities
      // Note that we create a new array to avoid mutating the original data set
      const allResults = [...this.states].filter((state) => {
        state.filteredCities = state.cities.filter((city) => {
          city.filteredStations = city.stations;
          return city.filteredStations.length > 0;
        });
        return state.filteredCities.length > 0;
      });

      const trimmed = this.search.trim();
      if (!trimmed) return allResults;

      const searchTerms = trimmed.split(' ').map((term) => term.toLowerCase());
      if (searchTerms.length < 1) return allResults;

      // For a search query, we want to filter the already filtered results set, so use allResults
      const filteredStates = allResults.filter((state) => {
        // If the state name matches, return the state and all it's cities
        if (matchSearchProp(state.name, searchTerms)) return true;

        // Otherwise update the filteredCities list
        // Note that we re-filter the filteredCities list because cities contains the original data
        //   which may include cities with 0 stations
        state.filteredCities = state.filteredCities.filter((city) => {
          // If the city name matches return the city and all its stations
          if (matchSearchProp(city.name, searchTerms)) return true;

          // Otherwise, update the filteredStations list
          // Note that we re-filter the filteredStations list, to imitate the city filtering above
          //   but in reality, there was no previous filtering applied to the stations, so we could
          //   just filter on city.stations.
          city.filteredStations = city.filteredStations.filter((station) => {
            if (matchSearchProp(station.label, searchTerms)) return true;
            if (matchSearchProp(station.frequency, searchTerms)) return true;
          });
          // If there's more than one filtered station in the results, return the city so it shows in results
          if (city.filteredStations.length > 0) return true;
        });
        // If there's more than one filtered city in the results, return the state so it shows in results
        if (state.filteredCities.length > 0) return true;
      });
      return filteredStates;
    },
    userFilteredState() {
      const state = this.filteredStates.find(
        (state) => state.id === this.user.state_id
      );
      return state;
    },
  },
  data() {
    return {
      search: '',
      errorMessage: null,
      channels: null,
    };
  },
  mounted() {
    if (this.states) this.refresh();
  },
  methods: {
    refresh() {
      this.errorMessage = null;
      this.$apollo.queries.states.refetch();
    },
    handleError(error) {
      this.errorMessage = error.message;
    },
    setStates(states) {
      this.states = states;
    },
  },
};

const matchSearchProp = (prop, searchTerms) => {
  const formattedProp = (prop || '').toLowerCase();
  const matches = searchTerms.filter((term) => {
    return formattedProp.indexOf(term) > -1;
  });
  return matches.length === searchTerms.length;
};
</script>

<style scoped>
.csn-station-list-group > .cs-textstyle-item-heading {
  margin-top: 24px;
  margin-bottom: 16px;
}
</style>
