<template>
  <ion-page>
    <app-main-header title="Schedule" />
    <ion-content>
      <ion-list
        v-if="loading"
        class="ion-padding"
        key="live-schedule-loading-list"
      >
        <skeleton w="100%" h="30px" />
        <skeleton-list-item v-for="i in 10" :key="i" />
      </ion-list>
      <!-- 
        NOTE: without key, vue has trouble distinguishing between the ion-lists
        and the 2nd list initially remains hidden even when loading is false
       -->
      <ion-list v-else class="live__schedule-list" key="live-schedule-list">
        <app-show-schedule focus-now-playing />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppShowSchedule from '@/components/shows/ShowSchedule.vue';
import SkeletonListItem from '@/components/skeletons/SkeletonListItem.vue';

import { mapState } from 'vuex';

export default {
  components: {
    AppMainHeader,
    AppShowSchedule,
    SkeletonListItem,
  },
  computed: {
    ...mapState('shows', {
      loading: 'isLoading',
      loadingError: 'loadingError',
    }),
  },
  mounted() {
    this.$store.dispatch('shows/fetch');
  },
};
</script>

<style scoped>
/* The schedule will provide padding */
ion-content {
  --padding-bottom: 0px;
}
.live__schedule-list {
  margin-bottom: 0;
}
</style>
