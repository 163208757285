<template>
  <ion-page>
    <app-secondary-header title="Contact Us" />
    <ion-content>
      <ion-list class="ion-padding">
        <div class="cs-textstyle-paragraph">
          PO Box 391<br />
          Twin Falls, ID 83303<br />
          <br />
          <app-phone-link phone="800.357.4226" /><br />
          <app-phone-link phone="208.432.3026" /> - Fax<br />
          <br />
          <h3>CSN Staff</h3>

          <div v-for="member in staffMembers" :key="member.name">
            <b>{{ member.name }}</b
            >, {{ member.role }}<br />
          </div>
        </div>

        <br />
        <i
          ><small
            >If you have a question, comment, or praise report and would like to
            contact us, please call or use the email form on our website.</small
          ></i
        >

        <div class="csn-contact__buttons">
          <cs-button @click="visitPrayerSite" corners="rounded">
            <i class="cs-icons-comment" />
            <span>Prayer Requests</span></cs-button
          >
          <br />
          <cs-button @click="visitWebsite" corners="rounded">
            <i class="cs-icons-email" />
            <span>Email Form</span></cs-button
          >
          <app-store-button />
        </div>
        <app-csn-social-buttons />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';
import AppCsnSocialButtons from '@/components/general/CsnSocialButtons.vue';
import AppPhoneLink from '@/components/general/PhoneLink.vue';
import AppStoreButton from '@/components/store/StoreButton.vue';

import $iab from '@/services/inappbrowser';

const staffMembers = [
  { name: 'Crystal Pamplona', role: 'Accounting' },
  { name: 'Dustin Pamplona', role: 'Director of Engineering' },
  { name: 'Jeremy Estabrooks', role: 'Field Engineer' },
  { name: 'Mike Stevens', role: 'Program Director' },
  { name: 'Ray Gorney', role: 'Assistant Director of Engineering' },
  { name: 'Scott Spencer', role: 'FCC Licensing' },
];

export default {
  components: {
    AppSecondaryHeader,
    AppCsnSocialButtons,
    AppPhoneLink,
    AppStoreButton,
  },
  data() {
    return {
      staffMembers,
    };
  },
  methods: {
    visitWebsite() {
      $iab.openExternal('https://csnradio.com/contact/');
    },
    visitPrayerSite() {
      $iab.openExternal('https://csnradio.com/prayer-requests/');
    },
  },
};
</script>

<style scoped>
.csn-contact__buttons {
  text-align: center;
}
.csn-contact__buttons > * {
  margin-top: 20px;
}
</style>
