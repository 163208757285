<template>
	<app-donate-details>
		<div class="cs-textstyle-item-heading csn-donate-details__title">
			Cancel Recurring Donation
		</div>
		<div class="cs-textstyle-paragraph csn-donate-details__description">
			<p>
				If at any moment you wish to cancel or stop a recurring donation from
				occurring, please call us at <app-phone-link phone="800-357-4226" />
			</p>
		</div>
		<div class="cs-textstyle-item-heading csn-donate-details__title">OR...</div>
		<div class="cs-textstyle-paragraph csn-donate-details__description">
			<p>
				Visit our website and fill in your details and an email will be sent to
				us letting us know that you'd like to stop your donations from
				repeating.
			</p>
		</div>
		<app-donate-button title="Visit Website" icon="" />
	</app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
import AppPhoneLink from '@/components/general/PhoneLink.vue';

export default {
	components: {
		AppDonateDetails,
		AppDonateButton,
		AppPhoneLink,
	},
};
</script>
