import { getApolloClient } from '@/vue-apollo';

import ListShows from '@/graphql/shows/ListShows.gql';

// import $toast from '@/services/toast';
const { DateTime } = require('luxon');

// Used to avoid checking for programs too frequently
let lastChecked = 0;
const lastCheckedInterval = 1000 * 60 * 10; // 10 minutes

const dayMap = {
  0: 'sunday',
  1: 'weekdays',
  2: 'weekdays',
  3: 'weekdays',
  4: 'weekdays',
  5: 'weekdays',
  6: 'saturday',
};

const getScheduleTemplate = () => {
  return {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
};

export default {
  namespaced: true,
  state: {
    programs: [],
    showsLookup: {},
    isLoading: false,
    loadingError: null,
    schedule: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
    liveShow: null,
    nextShows: null,
  },
  getters: {
    isShowLive: (state) => (show) => {
      if (!state.liveShow) return false;
      return (
        state.liveShow.id === show.id &&
        state.liveShow.time.valueOf() === show.time.valueOf()
      );
    },
    channelSchedule: (state) => (channelId) => {
      const schedule = getScheduleTemplate();
      Object.keys(state.schedule).forEach((day) => {
        schedule[day] = state.schedule[day].filter(
          (show) => show.channel_id === channelId
        );
      });
      return schedule;
    },
  },
  mutations: {
    setIsLoading(state, loadingState) {
      state.isLoading = loadingState;
    },
    setPrograms(state, programs) {
      state.programs = programs;
    },
    setShowsLookup(state, lookup) {
      state.showsLookup = lookup;
    },
    setSchedule(state, schedule) {
      state.schedule = schedule;
    },
    setLiveShow(state, show) {
      state.liveShow = show;
    },
    setNextShows(state, shows) {
      state.nextShows = shows;
    },
  },
  actions: {
    async fetch({ commit, state, dispatch }, forceRefresh) {
      if (!localStorage.csn_token) return;
      if (state.isLoading) return;
      if (!forceRefresh) {
        if (Date.now() - lastChecked < lastCheckedInterval) return;
      }
      commit('setIsLoading', true);
      const {
        data: { shows },
      } = await getApolloClient().query({
        query: ListShows,
        fetchPolicy: 'network-only',
      });

      // TODO: handle no response better
      if (!shows) {
        commit('setIsLoading', false);
        return;
      }

      shows.forEach((program) => {
        // Parse the time into an "US eastern" DateTime object (using luxon library)
        const [easternTime, easternAmPm] = program.time_eastern.split(' ');
        const [hours, minutes] = easternTime.split(':').map((n) => +n);
        let hours24 = hours % 12;
        if (easternAmPm === 'pm' && hours === 12) hours24 = 12;
        if (easternAmPm === 'pm' && hours !== 12) hours24 = (12 + hours) % 24;
        const dateTime = DateTime.fromObject(
          { hour: hours24, minute: minutes },
          { zone: 'America/New_York' }
        );
        program.date_time_eastern = dateTime;
      });

      // Create a lookup table where we can look up any individual program(show) by its table ID
      const lookup = {};
      shows.forEach((show) => {
        lookup[show.id] = { ...show };
      });

      // Create a weekly schedule starting from Sunday (day 0) through Saturday (day 6)
      const allShows = Object.values(lookup);
      const schedule = getScheduleTemplate();
      for (let i = 0; i < 7; i += 1) {
        let nowEastern = DateTime.fromObject({}, { zone: 'America/New_York' });
        nowEastern = nowEastern.plus({ days: i });
        const day = dayMap[nowEastern.weekday % 7];
        const showsForDay = allShows.filter((s) => s.days === day);
        showsForDay.forEach((show) => {
          show.date_time_eastern = show.date_time_eastern.set({
            day: nowEastern.day,
            month: nowEastern.month,
            year: nowEastern.year,
          });
          let time = show.date_time_eastern.toLocal();
          const dayDifference = time.day - DateTime.local().day;
          if (dayDifference > 6) {
            time = time.minus({ days: dayDifference });
          }
          schedule[time.weekday % 7].push({ ...show, time });
        });
      }
      Object.values(schedule).forEach((sched) =>
        sched.sort((a, b) => a.time.valueOf() - b.time.valueOf())
      );

      commit('setIsLoading', false);
      commit('setShowsLookup', lookup);
      commit('setPrograms', shows);
      commit('setSchedule', schedule);

      dispatch('checkNowPlaying');
      lastChecked = Date.now();
    },
    checkNowPlaying({ commit, state, dispatch }) {
      const schedule = state.schedule;
      const todayShows = schedule[new Date().getDay()];
      const now = Date.now();
      const possibleStarts = todayShows.filter((show) => {
        const showTime = show.time.valueOf();
        return showTime < now;
      });
      const liveShow = possibleStarts[possibleStarts.length - 1];
      commit('setLiveShow', liveShow);

      const tomorrowShows = schedule[(new Date().getDay() + 1) % 7];
      const possibleEnds = todayShows.filter((show) => {
        const showTime = show.time.valueOf();
        return showTime > now;
      });
      const nextShows = [...possibleEnds, ...tomorrowShows].splice(0, 3);
      commit('setNextShows', nextShows);

      dispatch('audio/updateNowPlaying', liveShow, { root: true });
    },
  },
};
