<template>
  <ion-page>
    <ion-content class="csn-login__ion-content">
      <div class="csn-login__content" v-show="!loginWindowOpen">
        <div class="csn-login__content-items">
          <div class="csn-login__logo-background">
            <img class="csn-login__logo" :src="logo" alt="" />
          </div>
          <div class="csn-login__social-buttons">
            <cs-login-button
              v-for="provider in loginProviders"
              :key="provider"
              :network="provider"
              corners="rounded"
              @click="login(provider)"
            ></cs-login-button>
            <app-login-apple-button />
          </div>
          <div class="csn-login__or cs-textstyle-paragraph">Or</div>
          <cs-button variant="primary" corners="rounded" @click="loginAsGuest"
            >Login as a guest</cs-button
          >
        </div>
        <br />
        <app-privacy-terms class="csn-login__privacy-terms" />
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import AppPrivacyTerms from '@/components/general/PrivacyTerms.vue';
import AppLoginAppleButton from '@/components/general/LoginAppleButton.vue';

import { mapState } from 'vuex';

const logo = require('@/assets/images/CSN-white-logo.svg');

export default {
  components: {
    AppPrivacyTerms,
    AppLoginAppleButton,
  },
  data() {
    return {
      loginProviders: ['facebook', 'google'],
      logo,
    };
  },
  computed: {
    ...mapState('auth', {
      loginWindowOpen: 'loginWindowOpen',
    }),
  },
  mounted() {
    // auth.html will redirect to the Login page when logging in from web browser
    // App login will be handled in vuex auth module
    // TODO auth.html needs to handle difference of login vs add social to guest
    const url = new URL(
      window.location.origin + window.location.hash.replace('#', '')
    );
    const search = new URLSearchParams(url.search);
    const token = search.get('access_token');
    if (token) this.$store.dispatch('auth/completeGenericLogin', token);
  },
  methods: {
    login(provider) {
      this.$store.dispatch('auth/loginProvider', provider);
    },
    loginAsGuest() {
      this.$store.dispatch('auth/loginAsGuest');
    },
  },
};
</script>
<style scoped>
ion-content {
  --padding-bottom: 0;
  --background: var(--cs-white);
}
.csn-login__content {
  background: url('~@/assets/images/Onboarding Background.svg');
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.csn-login__content-items {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
.csn-login__logo-background {
  background-color: #0192f9;
  height: 245px;
  width: 245px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.csn-login__social-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.csn-login__or {
  text-align: center;
  color: var(--cs-white);
  position: relative;
}
.csn-login__or:before {
  content: '';
  display: block;
  width: 50px;
  height: 1px;
  background: var(--cs-white);
  opacity: 0.42;
  left: 50px;
  top: 50%;
  position: absolute;
}
.csn-login__or:after {
  content: '';
  display: block;
  width: 50px;
  height: 1px;
  background: var(--cs-white);
  opacity: 0.42;
  right: 50px;
  top: 50%;
  position: absolute;
}
.csn-login__privacy-terms >>> .csn-privacy-terms__links {
  color: var(--cs-white);
}
</style>
