<template>
  <ion-header class="csn-main-header">
    <slot />
    <cs-secondary-header
      v-if="!$slots.default"
      class="csn-main-header__header"
      :title="title"
      align-title="center"
      back-icon=""
      @back="doNothing"
    >
      <cs-button
        v-if="buttonRight"
        slot="buttons"
        @click="$emit('button-right')"
        fill="clear"
        >{{ buttonRight }}</cs-button
      >
      <i
        slot="buttons"
        :class="icon"
        v-if="icon"
        @click="$emit('button-right')"
      ></i>
      <slot name="right" slot="buttons"></slot>
    </cs-secondary-header>
  </ion-header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonRight: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
  },
  methods: {
    doNothing() {
      // Prevents the back button event
    },
  },
};
</script>

<style scoped>
.csn-main-header .csn-main-header__header {
  box-shadow: none;
  --cs-secondary-header-background-color: var(--csn-gray-primary);
}
.dark .csn-main-header i {
  color: var(--cs-white);
}
</style>
