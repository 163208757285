<template>
  <div class="csn-channel-suggestion-list-item">
    <div class="csn-channel-suggestion-list-item__top-bar">
      <img
        class="csn-channel-suggestion-list-item__image"
        :src="channel.picture"
      />
      <div class="csn-channel-suggestion-list-item__titles">
        <div
          class="csn-channel-suggestion-list-item__title cs-textstyle-detail-heading"
        >
          {{ channel.title }}
        </div>
        <div
          class="csn-channel-suggestion-list-item__speaker cs-textstyle-paragraph-small-bold"
        >
          {{ channel.speaker }}
        </div>
        <div
          class="csn-channel-suggestion-list-item__speaker cs-textstyle-paragraph-small"
        >
          {{ this.followersCount }}
          {{ this.followersCount == 1 ? 'Follower' : 'Followers' }}
        </div>
      </div>
      <div class="csn-channel-suggestion-list-item__button-right">
        <app-channel-follow-button :channel="channel" @followed="onFollowed" />
      </div>
    </div>
  </div>
</template>
<script>
import AppChannelFollowButton from '@/components/channels/ChannelFollowButton.vue';

export default {
  components: {
    AppChannelFollowButton,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    followersCount() {
      return new Intl.NumberFormat('en-GB', {
        notation: 'compact',
        compactDisplay: 'short',
      }).format(this.channel.users_aggregate.aggregate.count);
    },
  },
  methods: {
    onFollowed(event) {
      this.$emit('followed', event);
    },
  },
};
</script>
<style scoped>
.csn-channel-suggestion-list-item {
  background-color: var(--csn-gray-secondary);
  color: var(--cs-gray-08);
  margin-bottom: 16px;
}
.csn-channel-suggestion-list-item__top-bar {
  display: flex;
  gap: 10px;
}
.csn-channel-suggestion-list-item__image {
  height: 60px;
  width: 68px;
  object-fit: cover;
}
.csn-channel-suggestion-list-item__titles {
  flex: 1;
  min-width: 0;
  align-self: center;
}
.csn-channel-suggestion-list-item__title,
.csn-channel-suggestion-list-item__speaker {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.csn-channel-suggestion-list-item__speaker {
  color: var(--cs-gray-05);
}
.csn-channel-suggestion-list-item__button-right {
  margin-top: 15px;
  margin-right: 15px;
}
</style>
