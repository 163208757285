<template>
  <div>
    <div
      class="csn-station-list-item"
      v-for="station of stations"
      :key="station.id"
    >
      <div class="csn-station-list-item__name cs-textstyle-detail-heading">
        {{ city.name }}
      </div>
      <div class="csn-station-list-item__content-right">
        <span class="cs-textstyle-paragraph">
          {{ station.label }}
        </span>
        &nbsp;<span class="cs-textstyle-paragraph">
          {{ station.frequency }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    city: {
      type: Object,
      required: true,
    },
    stations: {
      type: Array,
      required: true,
    },
  },
  methods: {},
};
</script>
<style scoped>
.csn-station-list-item {
  background-color: var(--csn-gray-secondary);
  color: var(--cs-gray-08);
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  padding-left: 16px;
  padding-right: 16px;
}
.csn-station-list-item__content-right > .cs-textstyle-paragraph:first-child {
  color: var(--cs-primary-base);
}
</style>
