<template>
  <ion-page>
    <ion-content>
      <div class="ion-text-center csn-channel-suggestions__heading">
        <div class="cs-textstyle-item-heading">Suggestions for you</div>
        <div class="cs-textstyle-informative-paragraph">
          Follow programs to get notified <br />when they go live
        </div>
      </div>
      <div class="csn-channel-suggestions__content">
        <ion-list class="ion-padding">
          <cs-spinner v-if="$apollo.queries.channels.loading" />
          <template v-if="channels">
            <app-channel-suggestion-list-item
              v-for="channel in channels"
              :key="channel.id"
              :channel="channel"
              @followed="onFollowed"
            />
          </template>
          <cs-empty-state
            v-if="showEmptyState"
            description="No programs suggestions found."
          >
          </cs-empty-state>
          <cs-empty-state
            v-if="errorMessage"
            title="Error loading programs"
            :description="errorMessage"
          >
            <cs-button size="small" corners="rounded" @click="refresh"
              >Retry</cs-button
            >
          </cs-empty-state>
        </ion-list>
        <div class="csn-channel-suggestions__button--continue">
          <cs-button
            block
            variant="primary"
            corners="rounded"
            @click="onContinue"
            >Continue</cs-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppChannelSuggestionListItem from '@/components/channels/ChannelSuggestionListItem.vue';
import * as simpleStorage from '@/services/simple-storage';

// GQL
import ListPopularChannels from '@/graphql/channels/ListPopularChannels.gql';

export default {
  components: {
    AppChannelSuggestionListItem,
  },
  apollo: {
    channels: {
      query: ListPopularChannels,
      error: (error, vm) => {
        vm.handleError(error);
        return false;
      },
    },
  },
  computed: {
    showEmptyState() {
      return !this.channels;
    },
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  methods: {
    handleError(error) {
      this.errorMessage = error.message;
    },
    refresh() {
      this.errorMessage = null;
      this.$apollo.queries.channels.refetch();
    },
    async onContinue() {
      await simpleStorage.setItem('csn_onboarded', 1);
      this.$router.push('/tabs/home');
    },
    onFollowed(event) {
      const [channelId, followed] = event;
      const channel = this.channels.find((channel) => channel.id === channelId);
      channel.users_aggregate.aggregate.count += followed ? 1 : -1;
    },
  },
};
</script>

<style scoped>
ion-content {
  --padding-bottom: 0;
}
.csn-channel-suggestions__heading {
  margin: 3rem 2rem 1rem 2rem;
}
.csn-channel-suggestions__content {
  position: relative;
}
.csn-channel-suggestions__content ion-list {
  overflow: auto;
  margin: 0;
  padding-bottom: calc(40px + 16px + env(safe-area-inset-bottom));
}

.csn-channel-suggestions__button--continue {
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  bottom: calc(16px + env(safe-area-inset-bottom));
  width: 100%;
}
</style>
