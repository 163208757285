<template>
  <div class="csn-dark-mode-toggle">
    <div class="cs-textstyle-detail-heading csn-dark-mode-toggle__title">
      THEME
    </div>
    <cs-button-group
      :values="selectedTheme"
      size="small"
      fade
      :buttons="themeButtons"
      @input="selectTheme"
    />
  </div>
</template>
<script>
import $theme from '@/services/theme';
import * as simpleStorage from '@/services/simple-storage';

const themeButtons = [
  { label: 'Auto', value: 'auto' },
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
];

export default {
  async mounted() {
    try {
      const theme = await simpleStorage.getItem('theme');
      const defaultTheme = themeButtons[0];
      const userTheme = themeButtons.find((button) => button.value === theme);
      this.selectedTheme = [userTheme || defaultTheme];
    } catch (err) {
      this.selectedTheme = null;
    }
  },
  data() {
    return {
      themeButtons: themeButtons,
      selectedTheme: null,
    };
  },
  methods: {
    async selectTheme([button]) {
      if (button) {
        try {
          await simpleStorage.setItem('theme', button.value);
          $theme.update();
        } catch (err) {
          $theme.update();
        }
      }
    },
  },
};
</script>
<style scoped>
.csn-dark-mode-toggle__title {
  color: var(--cs-gray-04);
}
</style>
