<template>
  <div v-if="loading">
    <skeleton w="100px" h="15px" />
    <skeleton-list-item v-for="i in 2" :key="i" />
  </div>

  <div class="csn-upcoming-shows-list" v-else>
    <cs-category-heading title="Up Next" />
    <template v-if="shows && shows.length">
      <app-show-list-item v-for="show in shows" :key="show.id" :show="show" />
    </template>
    <cs-empty-state
      v-if="showEmptyState"
      description="No upcoming shows right now"
    />
    <cs-empty-state
      v-if="errorMessage"
      title="Error loading next shows"
      :description="errorMessage"
    >
      <cs-button size="small" @click="refresh">Retry</cs-button>
    </cs-empty-state>
  </div>
</template>
<script>
import AppShowListItem from '@/components/shows/ShowListItem.vue';
import SkeletonListItem from '@/components/skeletons/SkeletonListItem.vue';
import { mapState } from 'vuex';

export default {
  components: {
    AppShowListItem,
    SkeletonListItem,
  },
  computed: {
    ...mapState('shows', {
      loading: 'isLoading',
      errorMessage: 'loadingError',
      shows: 'nextShows',
    }),
    showEmptyState() {
      return !this.loading && this.shows && !this.shows.length;
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch('shows/fetch');
    },
  },
};
</script>
