<template>
  <div class="csn-donate-button">
    <cs-button corners="rounded" @click="donate" v-bind="$attrs">
      <i :class="icon" />
      <span class="csn-donate__button-text">{{ title }}</span>
    </cs-button>
  </div>
</template>
<script>
import $dialog from '@/services/dialog';
import $iab from '@/services/inappbrowser';

const csnDonateLink = 'https://csnradio.com/donate/';

export default {
  props: {
    title: {
      type: String,
      default: 'Donate Now',
    },
    icon: {
      type: String,
      default: 'csn-donate',
    },
  },
  methods: {
    async donate() {
      const proceed = await $dialog.confirm({
        title: 'You will now be redirected to our website',
        okayLabel: 'Ok',
        cancelLabel: 'Back',
      });
      if (!proceed) return;
      $iab.openExternal(csnDonateLink);
    },
  },
};
</script>
<style scoped>
.csn-donate-button {
  margin-top: 32px;
  text-align: center;
}
</style>
