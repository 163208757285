<template>
  <ion-list class="skeleton__wrapper">
    <skeleton w="100%" h="400px" />
    <div class="ion-margin skeleton__wrapper__channel-profile">
      <div>
        <skeleton w="150px" h="15px" />
        <skeleton w="100px" h="10px" />
      </div>
      <div class="ion-margin-top">
        <skeleton w="100%" h="10px" v-for="i in 4" :key="i" />
      </div>
    </div>
    <div class="skeleton__wrapper__button">
      <skeleton w="80px" h="30px" />
    </div>
    <div class="ion-padding">
      <skeleton w="100%" h="30px" />
      <skeleton-list-item v-for="i in 10" :key="i" />
    </div>
  </ion-list>
</template>

<script>
import SkeletonListItem from '@/components/skeletons/SkeletonListItem.vue';
export default {
  components: {
    SkeletonListItem,
  },
};
</script>

<style scoped>
.skeleton__wrapper__channel-profile {
  display: flex;
  flex-direction: column;
}

.skeleton__wrapper__channel-profile > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.skeleton__wrapper__channel-profile > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.skeleton__wrapper__button {
  float: right;
  margin-right: 16px;
  margin-bottom: 16px;
}
</style>
