import Vue from 'vue';
import Vuex from 'vuex';
import audio from './modules/audio';
import auth from './modules/auth';
import follows from './modules/follows';
import notifications from './modules/notifications';
import shows from './modules/shows';

Vue.use(Vuex);

export let store = new Vuex.Store({
  modules: {
    audio,
    auth,
    follows,
    notifications,
    shows,
  },
});
